<div mdbDropdown class="dropdown">
  <button [class.disabled]="disabled" [variant]="variant" type="button" [id]="id" sch-button [size]="size" [color]="color"
    [fontColor]="fontColor" [shape]="shape" [hideBorder]="hideBorder" mdbDropdownToggle>
    <i *ngIf="icon" [sch-icon]="icon"></i>
    <span *ngIf="label" class="label">{{ label }}</span>
    <i *ngIf="showDropDownIcon" [sch-icon]="'collapse'"></i>
  </button>
  
  <ul [id]="computeId('dropdown-menu')" mdbDropdownMenu class="dropdown-menu" [class]="menuAlignmentClasses"
    [attr.aria-labelledby]="id">
    <ng-content> </ng-content>
  </ul>
</div>