import { Injectable } from '@angular/core';
import { Utilities } from '@seech/shared-logic-ng';

import {
  MdbNotificationService,
  MdbNotificationRef,
} from 'mdb-angular-ui-kit/notification';

import { BehaviorSubject } from 'rxjs';

interface notificationMethodsProps {
  componentToBeNotified: any;
  message: string;
  config?: config;
  bannerType?: string;
}

interface config {
  position: any;
  width: any;
  delay: number;
  autohide: boolean;
  stacking: boolean;
  offset: number;
  animation: boolean;
  data: {
    type: string;
    message: string;
    config: config;
    uId: string;
    id: string | number;
  };
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  notificationRef: MdbNotificationRef<any> | null = null;
  notifiedComponent: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private stackedComponents:any = [];

  constructor(private notificationService: MdbNotificationService) {}

  private defaultConfig = {
    position: 'top-right',
    width: 300,
    delay: 3000,
    autohide: true,
    stacking: true,
    offset: 30,
    animation: true,
  };

  private buildNotificationConfig(
    config: config | any,
    type?: string | any,
    message?: string
  ): config {
    const dataForComponetUse = {
      type: type,
      message: message,
      config: config || this.defaultConfig,
      refId: Utilities.generateUUID(),
    };

    const configSync = {
      ...this.defaultConfig,
      ...config,
      data: dataForComponetUse,
    };
    return configSync;
  }
  getNotifiedComponentRef() {
    return this.notifiedComponent;
  }

  getNotificationRef() {
    return this.notificationRef;
  }

  getStackedComponents() {
    return this.stackedComponents;
  }

  private storeStackedComponents(component: any) {
    this.stackedComponents.unshift(component);
  }

  info(dataToInject: notificationMethodsProps) {
    this.notificationRef = this.notificationService.open(
      dataToInject.componentToBeNotified,
      {
        ...this.buildNotificationConfig(
          dataToInject.config,
          'info',
          dataToInject.message
        ),
      }
    );
    this.notifiedComponent.next(this.notificationRef);
   this.storeStackedComponents(this.notificationRef)
  }

  success(dataToInject: notificationMethodsProps) {
    this.notificationRef = this.notificationService.open(
      dataToInject.componentToBeNotified,
      {
        ...this.buildNotificationConfig(
          dataToInject.config,
          'success',
          dataToInject.message
        ),
      }
    );
    this.notifiedComponent.next(this.notificationRef);
   this.storeStackedComponents(this.notificationRef)
  }

  error(dataToInject: notificationMethodsProps) {
    this.notificationRef = this.notificationService.open(
      dataToInject.componentToBeNotified,
      {
        ...this.buildNotificationConfig(
          dataToInject.config,
          'error',
          dataToInject.message
        ),
      }
    );
    this.notifiedComponent.next(this.notificationRef);
   this.storeStackedComponents(this.notificationRef)
  }

  warning(dataToInject: notificationMethodsProps) {
    this.notificationRef = this.notificationService.open(
      dataToInject.componentToBeNotified,
      {
        ...this.buildNotificationConfig(
          dataToInject.config,
          'warning',
          dataToInject.message
        ),
      }
    );
    this.notifiedComponent.next(this.notificationRef);
   this.storeStackedComponents(this.notificationRef)
  }

  banner(dataToInject: notificationMethodsProps) {
    this.notificationRef = this.notificationService.open(
      dataToInject.componentToBeNotified,
      {
        ...this.buildNotificationConfig(
          dataToInject.config,
          dataToInject?.bannerType,
          dataToInject.message
        ),
      }
    );
    this.notifiedComponent.next(this.notificationRef);
   this.storeStackedComponents(this.notificationRef)
  }

}
