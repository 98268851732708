import { Injectable } from '@angular/core';
import {
  MdbModalRef,
  MdbModalService,
} from 'mdb-angular-ui-kit/modal';
import { DialogComponent } from '../components/dialog/dialog.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private modalRef: MdbModalRef<any> | null = null;
  constructor(
    private modalService: MdbModalService,
    private sanitizer: DomSanitizer
  ) {}

  open(title: string, content: string, btnConfig:any) {

    const safeTitle: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(title);
    const safeContent: SafeHtml =this.sanitizer.bypassSecurityTrustHtml(content);

    const modalRef = this.modalService.open(DialogComponent, {
      data: {
        title: safeTitle,
        content: safeContent,
        actionBtnClasses: btnConfig.actionBtnClasses,
        cancelBtnClasses: btnConfig.cancelBtnClasses,
        actionBtnText: btnConfig.actionBtnText,
        cancelBtnText: btnConfig.cancelBtnText,
        onAction: btnConfig.onAction,
        onCancel: btnConfig.onCancel,
      },
      animation: true,
      backdrop: true,
      containerClass: 'modal-container', // Default container class
      ignoreBackdropClick: false,
      keyboard: true,
      modalClass: 'modal-dialog', // Default modal class
      nonInvasive: false, // Default to MDB modal behavior
    });

    this.modalRef = modalRef; // Store the modal reference

    return modalRef;
  }

    close(data?: any): void {
        // Close the modal and pass data when it's closed
        this.modalRef && this.modalRef.close(data);
    }
}
