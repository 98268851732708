<label
  *ngIf="label"
  class="sch-input-label"
  [class]="readonly ? 'text-primary' : 'mdb-text'"
  >{{ label }}</label
>
<div class="d-flex W-100">
  <ng-container *ngIf="showCurrencySelector">
    <button
      cdkOverlayOrigin
      #searchResultOrigin="cdkOverlayOrigin"
      [class]="getValidationColorClass()"
      [disabled]="disabled"
      [style.font-size]="fontSize"
      (click)="showSearchResult = true"
      type="button"
      class="money-dropdown d-flex justify-content-center align-items-center gap-1"
    >
      <img
        *ngIf="currency?.currencyCode"
        width="19"
        src="https://flagcdn.com/{{ currency?.countryCode | lowercase }}.svg"
      />
      {{ currency ? currency.currencyCode : '' }}
      <span
        *ngIf="!disabled"
        [sch-icon]="showSearchResult ? 'arrow-drop-up' : 'arrow-drop-down'"
      ></span>
    </button>
    <ng-template
      cdkConnectedOverlay
      cdkConnectedOverlayHasBackdrop
      (detach)="showSearchResult = false"
      cdkConnectedOverlayBackdropClass="bg-transparent"
      [cdkConnectedOverlayPositions]="connectedPositions"
      (backdropClick)="showSearchResult = false"
      [cdkConnectedOverlayOpen]="showSearchResult"
      [cdkConnectedOverlayOrigin]="searchResultOrigin"
    >
      <div class="search-result mdb-bg-body">
        <div class="search-input">
          <input
            [style.font-size]="fontSize"
            (keyup)="onSearch($event)"
            type="text"
            class="w-100 border-0 currency-search mdb-bg-body"
            [placeholder]="currencySearchPlaceholder"
          />
          <i [sch-icon]="'search'"></i>
        </div>
        <hr class="m-0" />
        <div
          class="overlay-content"
          sch-scroll-end-notifier
          (scrollEnd)="onScrollEnd()"
          [offset]="threshold"
        >
          <button
            *ngFor="let currency of currencies"
            (click)="onSelected(currency); showSearchResult = false"
            [style.font-size]="fontSize"
            class="list-item d-flex gap-2 mdb-bg-body align-items-center list-group-item-action list-group-item"
          >
            <img
              width="25"
              src="https://flagcdn.com/{{
                currency.countryCode | lowercase
              }}.svg"
            />
            <span
              >{{ currency.currencyName }} (<span
                [innerHTML]="currency.currencySymbol"
              ></span>
              {{ currency.currencyCode }})</span
            >
          </button>
        </div>
      </div>
    </ng-template>
  </ng-container>
  <fieldset
    class="flex-grow-1"
    [attr.data-before]="htmlSymbolToUnicode(currencySymbol!)"
    [disabled]="disabled"
    [class.money-container]="currencySymbol"
    [class]="getTextColorClass()"
  >
    <input
      [style.font-size]="fontSize"
      type="text"
      [(ngModel)]="formattedValue"
      (keyup)="onInputChange($event)"
      (blur)="onBlur()"
      (focus)="onFocus()"
      class="money-input-control"
      [class.input-with-symbol]="currencySymbol"
      [class.input-with-selector]="showCurrencySelector"
      [autocomplete]="autocomplete"
      [placeholder]="placeholder"
      [disabled]="disabled"
      [readonly]="readonly"
      [class]="getValidationColorClass()"
    />
  </fieldset>
</div>
<div
  *ngIf="showError === true && validateControl"
  class="text-danger error-label"
>
  {{ errorMessage }}
</div>
