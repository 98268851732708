<div class="date-time sch-input" [formGroup]="form">

  <div class="control-row">
    <seech-date
      #datePicker
      [disabled]="disabled"
      (valueChange)="onDateSelected($event)"
      (opened)="onDateOpened($event)"
      (closed)="
        onDateClosed($event);
        isDateClosed === true ? timePicker.openTimePicker() : null
      "
      formControlName="dateControl"
      [required]="dateRequired"
      [disablePast]="disableDatePast"
      [disableFuture]="disableDateFuture"
      [value]="selectedDate"
      [placeholder]="datePlaceholder"
      [label]="label"
      [inline]="inlineDate"
      [id]="dateId"
      [format]="dateFormat"
      [minDate]="minDate"
      [maxDate]="maxDate"
      [readonly]="readonly"
      [disabled]="disabled"
    ></seech-date>

    <seech-time
      [format24]="format24"
      (timeChange)="onTimeChanged($event)"
      (closed)="onTimeClosed($event)"
      [useInline]="inlineTime"
      [id]="timeId"
      [maxTime]="maxTime"
      (opened)="onTimeOpen($event)"
      #timePicker
      [minTime]="minTime"
      [value]="selectedTime"
      [placeholder]="timePlaceholder"
      [disabled]="disabled"
      (openTime)="triggerTime()"
      formControlName="timeControl"
      [readonly]="readonly"
      [disabled]="disabled"
    ></seech-time>
  </div>
</div>
