import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  Color,
  SpinnerComponent as SharedSpinnerComponent,
} from '@seech/shared-ng';

@Component({
  selector: 'seech-spinner',
  standalone: true,
  imports: [SharedSpinnerComponent],
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent extends SharedSpinnerComponent {
}
