import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  SideNavComponent,
  GoldLayoutComponent,
  StandardLayoutComponent,
} from './layouts';
import { LayoutContentComponent, SideNavItemsComponent, SideNavToggleComponent } from './components';

@NgModule({
  imports: [
    CommonModule,
    CdkScrollableModule,
    SideNavItemsComponent,
    SideNavToggleComponent,
  ],
  exports: [
    CdkScrollableModule,
    SideNavComponent,
    StandardLayoutComponent,
    GoldLayoutComponent,
    SideNavItemsComponent,
    LayoutContentComponent,
    SideNavToggleComponent,
  ],
  declarations: [
    SideNavComponent,
    StandardLayoutComponent,
    GoldLayoutComponent,
    LayoutContentComponent,
  ],
})
export class LayoutNgModule {}
