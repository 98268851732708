import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Color } from '../../constants';

@Component({
  selector: 'seech-shared-spinner',
  standalone: true,
  imports: [],
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent implements OnChanges, AfterViewInit {
  @ViewChild('spinner') spinnerRef!: ElementRef;
  @Input() color?: Color;
  @Input() size?: string;
  @Input() strokeWidth?: number;
  classes = this.getSpinnerColorClass();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    this.classes = this.getSpinnerColorClass();
  }

  ngAfterViewInit(): void {
    const spinner = this.spinnerRef?.nativeElement as HTMLElement;
    if(spinner){
      if(this.size)
        spinner.style.width = spinner.style.height = this.size;
      if(this.strokeWidth)
        spinner.style.borderWidth = this.strokeWidth + 'px';
    }
  }

  getSpinnerColorClass() {
    if (this.color) {
      return `loader-${this.color}`;
    }
    return '';
  }

}
