<!-- <div mdbDropdown class="dropdown btn-group" [class.shadow-none]="hasShadow">
  <button *ngIf="split" [class.disabled]="disabled" [fill]="fill" type="button" [id]="id" sch-button [size]="size"
    [color]="color">
    <i *ngIf="icon" [sch-icon]="icon"></i>
    <span class="label">&nbsp;{{ label }} &nbsp;</span>
  </button>

  <div *ngIf="split" class="vr" [class]="dividerColorClass"></div>

  <button style="vertical-align: middle;" class="dropdown-toggl" [fill]="fill" type="button" [class]="classes" [id]="id" aria-expanded="false"
    mdbDropdownToggle sch-button [size]="size" [color]="color" [class.disabled]="disabled">
    <ng-container *ngIf="!split">
      <i *ngIf="icon" [sch-icon]="icon"></i>
      <span class="label">&nbsp;{{ label }} &nbsp;</span>
    </ng-container>
    <i [sch-icon]="'collapse'"></i>
  </button>

  <ul [id]="computeId('dropdown-menu')" mdbDropdownMenu class="dropdown-menu" [class]="menuAlignmentClasses"
    [attr.aria-labelledby]="id">
    <ng-content> </ng-content>
  </ul>
</div> -->

<div mdbDropdown class="dropdown">
  <ng-container *ngIf="!split; else splitDropDown">
    <button [class.disabled]="disabled" [fill]="fill" type="button" [id]="id" sch-button [size]="size" [color]="color"
      [fontColor]="fontColor" [shape]="shape" mdbDropdownToggle>
      <i *ngIf="icon" [sch-icon]="icon"></i>
      <span class="label">{{ label }}</span>
      <i [sch-icon]="'collapse'"></i>
    </button>
  </ng-container>

  <ng-template #splitDropDown>
    <button [class.disabled]="disabled" [fill]="fill" type="button" [id]="id" sch-button [size]="size" [color]="color"
    [fontColor]="fontColor" [shape]="shape" (click)="mainBtnClick.emit()">
      <i *ngIf="icon" [sch-icon]="icon"></i>
      <span class="label"> {{ label }} </span>
      <div [class]="'split-divider ' + getDividerClassOrColor" [style]="'background-color: ' + getDividerClassOrColor"></div>
      <i mdbDropdownToggle [sch-icon]="'collapse'" (click)="$event.preventDefault()"></i>
    </button>
  </ng-template>

  <ul [id]="computeId('dropdown-menu')" mdbDropdownMenu class="dropdown-menu" [class]="menuAlignmentClasses"
    [attr.aria-labelledby]="id">
    <ng-content> </ng-content>
  </ul>
</div>