import { CommonModule } from '@angular/common';
import { Component, Input} from '@angular/core';

@Component({
    selector: 'seech-tab',
    standalone: true,
    imports: [CommonModule],
    template: '<ng-content *ngIf="active"></ng-content>',
    styleUrls: ['./tabs.component.scss']
})
export class TabComponent {
    @Input() active = false;
    @Input() title = '';
}
