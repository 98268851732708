import {
  Directive,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[sch-base-dropdown-item]',
  standalone: true,
})
export class BaseDropdownDirective implements OnChanges {
  @HostBinding('class') hostElementClass = this.getHostElementClasses();

  @HostBinding('attr.aria-current')
  @Input() active = false;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    this.hostElementClass = this.getHostElementClasses();
  }

  private getHostElementClasses() {
    const activeClass = this.active ? 'active' : '';
    return `dropdown-item ${activeClass}`;
  }
}
