import {
  Directive,
} from '@angular/core';
import { BaseDropdownDirective } from '@seech/shared-ng';

@Directive({
  selector: '[sch-dropdown-item]',
  standalone: true,
})
export class DropdownDirective extends BaseDropdownDirective {
}
