export const AllSettings = {
  general: [{ collapseAll: 'Collapse All', collapseAllValue: false }],
  controls: [
    { readonly: 'Read Only', readonlyValue: false },
    { disabled: 'Disabled', disabledValue: false },
    { showValues: 'Show Values', showValuesValue: false },
    { autoComplete: 'Auto-Complete', autocompleteValue: false },
    {
      textboxVariant: 'Textbox Variant',
      textboxOptions: [
        { value: 'Modern', label: 'Modern' },
        { value: 'Classic', label: 'Classic' },
        { value: 'Hybrid', label: 'Hybrid' },
      ],
      textboxValue: 'Hybrid',
    },
    {
      combobuttonVariant: 'Combo Button Variant',
      combobuttonOptions: [
        { value: 'Normal', label: 'Normal' },
        { value: 'Rounded', label: 'Rounded' },
      ],
      combobuttonValue: 'Rounded',
    },
    {
      dropdownVariant: 'Dropdown Variant',
      dropdownOptions: [
        { value: 'Normal', label: 'Normal' },
        { value: 'Rounded', label: 'Rounded' },
      ],
      dropdownValue: 'Normal',
    },
    {
      radio: 'Radio Button Variant',
      radioOptions: [
        { value: 'Normal', label: 'Normal' },
        { value: 'Circle', label: 'Circle' },
      ],
      radioValue: 'Normal',
    },
    {
      checkbox: 'Checkbox',
      checkboxOptions: [
        { value: 'Normal', label: 'Normal' },
        { value: 'Circle', label: 'Circle' },
      ],
      checkboxValue: 'Circle',
    },
    {
      buttonShape: 'Button Shape',
      buttonshapeOptions: [
        { value: 'Normal', label: 'Normal' },
        { value: 'Rounded', label: 'Rounded' },
        { value: 'Circle', label: 'Circle' },
      ],
      buttonshapeValue: 'Normal',
    },
    {
      switchVariant: 'Switch Variant',
      switchOptions: [
        { value: 'Classic', label: 'Classic' },
        { value: 'Modern', label: 'Modern' },
      ],
      switchValue: 'Modern',
    },
  ],
  media: {
    audio: [
      { autoplay: 'Autoplay', autoplayValue: false },
      { showEqualizer: 'Show Equalizer', showEqualizerValue: false },
      {
        defaultVolume: 'Default Volume',
        defaultVolumeOptions: [
          { value: '0%', label: '0%' },
          { value: '25%', label: '25%' },
          { value: '50%', label: '50%' },
          { value: '75%', label: '75%' },
          { value: '100%', label: '100%' },
        ],
        defaultVolumeValue: '',
      },
    ],

    video: [
      { autoplay: 'Autoplay', autoplayValue: true },
      { videoQuality: 'Video Quality', videoQualityValue: false },
      { enableSubtitle: 'Enable Subtitle', enableSubtitleValue: false },
      { loopVideo: 'Loop Video', loopVideoValue: false },
      { enableFullscreen: 'Enable Fullscreen', enableFullscreenValue: false },
      { enablePIP: 'Enable picture-in-picture', enablePIPValue: false },
    ],
  },
};
