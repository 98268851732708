import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AllSettings } from './settings';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private allSettingsSubject: BehaviorSubject<any>;

  constructor() {
    let storedSettings = sessionStorage.getItem('appSettings');

    if (!storedSettings) {
      // If no settings are in sessionStorage, set the default AllSettings
      sessionStorage.setItem('appSettings', JSON.stringify(AllSettings));
      storedSettings = JSON.stringify(AllSettings);
    }

    // Initialize the BehaviorSubject with the stored or default settings
    this.allSettingsSubject = new BehaviorSubject(JSON.parse(storedSettings));
  }

  getSettings() {
    return this.allSettingsSubject.asObservable();
  }

  updateSettings(updatedSettings: any) {
    this.allSettingsSubject.next(updatedSettings);
    sessionStorage.setItem('appSettings', JSON.stringify(updatedSettings));
  }
}
