import { Directive, HostBinding, Input, SimpleChanges, OnChanges, ElementRef } from '@angular/core';
import { Color, COLOR } from '@seech/shared-ng';
@Directive({
  selector: '[sch-color]',
  standalone: true,
})
export class ThemeColorDirective implements OnChanges {
  @Input() color: Color = COLOR.PRIMARY;


  @HostBinding('class')
  get hostElementClasses(): string {
    return `sch-color-${this.color}`;
  }

  constructor(private elRef: ElementRef) { }

  ngOnChanges(changes: SimpleChanges) {
    if (this.elRef && changes['color'] && !changes['color'].isFirstChange()) {
      const prevClass = `sch-color-${changes['color'].previousValue}`;
      const currentEl = this.elRef.nativeElement;
     // Access ElementRef (you might need to inject ElementRef in the constructor)
      currentEl.classList.remove(prevClass);
      currentEl.classList.add(`sch-color-${changes['color'].currentValue}`);
    }
  }

}
