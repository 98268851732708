<div class="dialog p-3">
    <div class="dialog-title">
        <div class="divider d-none d-md-inline-block"></div>
        <p class="label d-none d-md-inline-block">Settings</p>
        <div class="divider"></div>
        <i [sch-icon]="'cancel'" class="close" (click)="closeModal()" role="button"></i>
    </div>

    <div class="dialog-body">
        <div class="dialog-title mobile d-md-none mt-2 mx-2">
            <div class="divider"></div>
            <p class="label">Settings</p>
            <div class="divider"></div>
        </div>

        <div class="content-wrapper mb-3">
            <seech-accordion [borderless]="false">
                <seech-accordion-item [collapsed]="generalCollapsed">
                    <div item-header class="sch-accordion-item-header">
                        <i [sch-icon]="'home'"></i>
                        General
                    </div>
                    <div item-body>
                        <div class="my-1">
                            <ng-container *ngFor="let item of allSettings.general">
                                <div *ngIf="item.collapseAll"
                                    class="d-flex justify-content-between align-items-center sets mb-0">
                                    <p class="sub-title-box mb-0">
                                        {{item.collapseAll}}
                                    </p>

                                    <seech-switch [id]="'general-collapseAll'" [color]="'primary'" [disabled]="false"
                                        [(selected)]="item.collapseAllValue" (selectedChange)="saveSettings($event)" />
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </seech-accordion-item>
            </seech-accordion>
        </div>

        <div class="content-wrapper mb-3">
            <seech-accordion [borderless]="false">
                <seech-accordion-item [collapsed]="controlsCollapsed">
                    <div item-header class="sch-accordion-item-header">
                        <i [sch-icon]="'toggle-off'"></i>
                        Controls
                    </div>
                    <div item-body>
                        <div class="my-1">
                            <ng-container *ngFor="let item of allSettings.controls">
                                <div *ngIf="item.readonly"
                                    class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.readonly}}
                                    </p>

                                    <seech-switch [id]="'readonly'" [color]="'primary'" [disabled]="false"
                                        [(selected)]="item.readonlyValue" (selectedChange)="saveSettings($event)" />
                                </div>

                                <div *ngIf="item.disabled"
                                    class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.disabled}}
                                    </p>

                                    <seech-switch [id]="'disabled'" [color]="'primary'" [disabled]="false"
                                        [(selected)]="item.disabledValue" (selectedChange)="saveSettings($event)" />
                                </div>

                                <div *ngIf="item.showValues"
                                    class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.showValues}}
                                    </p>

                                    <seech-switch [id]="'showValues'" [color]="'primary'" [disabled]="false"
                                        [(selected)]="item.showValuesValue" (selectedChange)="saveSettings($event)" />
                                </div>

                                <div *ngIf="item.autoComplete"
                                    class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.autoComplete}}
                                    </p>

                                    <seech-switch [id]="'autocomplete'" [color]="'primary'" [disabled]="false"
                                        [(selected)]="item.autocompleteValue" (selectedChange)="saveSettings($event)" />
                                </div>

                                <div *ngIf="item.textboxVariant"
                                    class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.textboxVariant}}
                                    </p>

                                    <seech-select [id]="'textbox-select'" [options]="item.textboxOptions"
                                        [(selectedValue)]="item.textboxValue" [placeholder]="'Choose option'"
                                        [label]="''" (selected)="saveSettings($event)">
                                    </seech-select>
                                </div>

                                <div *ngIf="item.combobuttonVariant"
                                    class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.combobuttonVariant}}
                                    </p>

                                    <seech-select [id]="'combobutton-select'" [options]="item.combobuttonOptions"
                                        [(selectedValue)]="item.combobuttonValue" [placeholder]="'Choose option'"
                                        [label]="''" (selected)="saveSettings($event)">
                                    </seech-select>
                                </div>

                                <div *ngIf="item.dropdownVariant"
                                    class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.dropdownVariant}}
                                    </p>

                                    <seech-select [id]="'dropdown-select'" [options]="item.dropdownOptions"
                                        [(selectedValue)]="item.dropdownValue" [placeholder]="'Choose option'"
                                        [label]="''" (selected)="saveSettings($event)">
                                    </seech-select>
                                </div>

                                <div *ngIf="item.radio" class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.radio}}
                                    </p>

                                    <seech-select [id]="'radio-select'" [options]="item.radioOptions"
                                        [(selectedValue)]="item.radioValue" [placeholder]="'Choose option'" [label]="''"
                                        (selected)="saveSettings($event)">
                                    </seech-select>
                                </div>

                                <div *ngIf="item.checkbox"
                                    class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.checkbox}}
                                    </p>

                                    <seech-select [id]="'checkbox-select'" [options]="item.checkboxOptions"
                                        [(selectedValue)]="item.checkboxValue" [placeholder]="'Choose option'"
                                        [label]="''" (selected)="saveSettings($event)">
                                    </seech-select>
                                </div>

                                <div *ngIf="item.buttonShape"
                                    class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.buttonShape}}
                                    </p>

                                    <seech-select [id]="'buttonshape-select'" [options]="item.buttonshapeOptions"
                                        [(selectedValue)]="item.buttonshapeValue" [placeholder]="'Choose option'"
                                        [label]="''" (selected)="saveSettings($event)">
                                    </seech-select>
                                </div>

                                <div *ngIf="item.switchVariant"
                                    class="d-flex justify-content-between align-items-center sets mb-0">
                                    <p class="sub-title-box mb-0">
                                        {{item.switchVariant}}
                                    </p>

                                    <seech-select [id]="'switch-select'" [options]="item.switchOptions"
                                        [(selectedValue)]="item.switchValue" [placeholder]="'Choose option'"
                                        [label]="''" (selected)="saveSettings($event)">
                                    </seech-select>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </seech-accordion-item>
            </seech-accordion>
        </div>

        <div class="content-wrapper mb-3">
            <seech-accordion [borderless]="false">
                <seech-accordion-item [collapsed]="mediaCollapsed">
                    <div item-header class="sch-accordion-item-header">
                        <i [sch-icon]="'play'"></i>
                        Media
                    </div>
                    <div item-body>
                        <div class="my-1">
                            <p class="sets media-header">Audio Settings</p>
                            <ng-container *ngFor="let item of allSettings.media.audio">
                                <div *ngIf="item.autoplay"
                                    class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.autoplay}}
                                    </p>

                                    <seech-switch [id]="'audio-autoplay'" [color]="'primary'" [disabled]="false"
                                        [(selected)]="item.autoplayValue" (selectedChange)="saveSettings($event)" />
                                </div>

                                <div *ngIf="item.showEqualizer"
                                    class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.showEqualizer}}
                                    </p>

                                    <seech-switch [id]="'audio-equalizer'" [color]="'primary'" [disabled]="false"
                                        [(selected)]="item.showEqualizerValue"
                                        (selectedChange)="saveSettings($event)" />
                                </div>

                                <div *ngIf="item.defaultVolume"
                                    class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.defaultVolume}}
                                    </p>

                                    <seech-select [id]="'audio-volume'" [options]="item.defaultVolumeOptions"
                                        [(selectedValue)]="item.defaultVolumeValue" [placeholder]="'Choose option'"
                                        [label]="''" (selected)="saveSettings($event)">
                                    </seech-select>
                                </div>
                            </ng-container>

                            <p class="sets media-header">Video Settings</p>
                            <ng-container *ngFor="let item of allSettings.media.video">
                                <div *ngIf="item.autoplay"
                                    class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.autoplay}}
                                    </p>

                                    <seech-switch [id]="'video-autoplay'" [color]="'primary'" [disabled]="false"
                                        [(selected)]="item.autoplayValue" (selectedChange)="saveSettings($event)" />
                                </div>

                                <div *ngIf="item.videoQuality"
                                    class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.videoQuality}}
                                    </p>

                                    <seech-switch [id]="'video-quality'" [color]="'primary'" [disabled]="false"
                                        [(selected)]="item.videoQualityValue" (selectedChange)="saveSettings($event)" />
                                </div>

                                <div *ngIf="item.enableSubtitle"
                                    class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.enableSubtitle}}
                                    </p>

                                    <seech-switch [id]="'enable-sutitle'" [color]="'primary'" [disabled]="false"
                                        [(selected)]="item.enableSubtitleValue"
                                        (selectedChange)="saveSettings($event)" />
                                </div>

                                <div *ngIf="item.loopVideo"
                                    class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.loopVideo}}
                                    </p>

                                    <seech-switch [id]="'loop-video'" [color]="'primary'" [disabled]="false"
                                        [(selected)]="item.loopVideoValue" (selectedChange)="saveSettings($event)" />
                                </div>

                                <div *ngIf="item.enableFullscreen"
                                    class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.enableFullscreen}}
                                    </p>

                                    <seech-switch [id]="'enable-fullscreen'" [color]="'primary'" [disabled]="false"
                                        [(selected)]="item.enableFullscreenValue"
                                        (selectedChange)="saveSettings($event)" />
                                </div>

                                <div *ngIf="item.enablePIP"
                                    class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.enablePIP}}
                                    </p>

                                    <seech-switch [id]="'enable-pip'" [color]="'primary'" [disabled]="false"
                                        [(selected)]="item.enablePIPValue" (selectedChange)="saveSettings($event)" />
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </seech-accordion-item>
            </seech-accordion>
        </div>

        <div class="content-wrapper">
            <seech-accordion [borderless]="false">
                <seech-accordion-item [collapsed]="iconsCollapsed">
                    <div item-header class="sch-accordion-item-header">
                        <i [sch-icon]="'priority'"></i>
                        Icons
                    </div>
                    <div item-body>
                        <div class="my-1">
                            <p class="sub-title-box">
                                Icon Settings
                            </p>
                        </div>
                    </div>
                </seech-accordion-item>
            </seech-accordion>
        </div>
    </div>
</div>