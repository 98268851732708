import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MdbAccordionModule } from 'mdb-angular-ui-kit/accordion';
@Component({
  selector: 'seech-accordion-item',
  standalone: true,
  imports: [CommonModule, MdbAccordionModule],
  template: `
    <mdb-accordion-item
      [collapsed]="collapsed"
      [disabled]="disabled"
      (itemShown)="onItemShown($event)"
    >
      <ng-template mdbAccordionItemHeader>
        <ng-content select="[item-header]"></ng-content>
      </ng-template>
      <ng-template mdbAccordionItemBody>
        <ng-content select="[item-body]"></ng-content>
      </ng-template>
    </mdb-accordion-item>
  `,
})
export class AccordionItemComponent {
  @Input() collapsed = false;
  @Input() disabled = false;
  @Output() itemExpanded = new EventEmitter<boolean>();

  onItemShown(e: any) {
    this.itemExpanded.emit(e);
  }
}
