import {
  Directive,
  HostBinding,
  Input,
} from '@angular/core';

@Directive({
  selector: '[sch-badge]',
  standalone: true,
})
export class BadgeDirective {
  @Input('sch-badge') showBadge = true;

  @HostBinding('class.position-relative') get position() {
    return this.showBadge;
  }
}
