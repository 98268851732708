<div
  class="sch-stepper"
  [ngClass]="showTopSection ? 'd-flex' : 'd-none'"
  [id]="id"
  [style.background-color]="stepperBackground"
>
  <ng-container *ngFor="let step of steps; let i = index">
    <div
      class="sch-stepper-item"
      (click)="selectStep(i)"
      [id]="computeId('step')"
    >
      <p
        class="m-0 sch-stepper-count"
        [ngClass]="{ active: step.active }"
        [style.background-color]="step.active ? activeColor : '#97A2B0'"
      >
        {{ i + 1 }}
      </p>
      <p
        [ngClass]="{ active: step.active }"
        [style.color]="step.active ? activeColor : 'rgba(0, 0, 0, 0.55)'"
        class="sch-stepper-title m-0"
        [innerHTML]="step.name"
      ></p>
    </div>
    <hr
      *ngIf="i !== steps.length - 1"
      class="divider"
      [style.color]="step.active ? activeColor : ''"
    />
  </ng-container>
</div>
<ng-content></ng-content>
