import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormsModule, NG_VALIDATORS, ReactiveFormsModule, ValidationErrors, Validator, Validators } from '@angular/forms';

import { CONTROL_TYPE } from '../../constants';
import { InputDirective } from '../../directives';
import { IconDirective } from '@seech/shared-ng';
import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';
import { ValidationComponent } from '../validation/validation.component';
import { ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import { SharedTooltipDirective } from '@seech/shared-ng';
import { InputComponent } from '../input/input.component';
import { TextboxVariant } from '../../injection/controls-config.model';
@Component({
  selector: 'seech-password',
  standalone: true,
  imports: [
    CommonModule,
    InputDirective,
    ReactiveFormsModule,
    FormsModule,
    ValidationComponent,
    MdbFormsModule,
    SharedTooltipDirective,
    InputComponent,
    IconDirective,
  ],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PasswordComponent),
      multi: true,
    },
  ],
})
export class PasswordComponent implements ControlValueAccessor, Validator, OnInit {
  @Input() label?: string;
  @Input() id = '';
  @Input() value: any = '';
  @Input() autocomplete: 'on' | 'off' = 'off';
  @Input() placeholder = '';
  @Output() valueChange: any = new EventEmitter<any>();
  @Output() valid: any = new EventEmitter<boolean>();
  @Input() variant!: TextboxVariant;
  @Input() disabled = false;
  type = CONTROL_TYPE.PASSWORD;

  validInput = true;
  passwordVisible = false;

  handleValidation(event: any): void {
    this.validInput = event;
    this.valid.emit(this.validInput);
  }

  handleVisibility(){
    this.passwordVisible = !this.passwordVisible;
    this.type = this.passwordVisible ? CONTROL_TYPE.TEXT : CONTROL_TYPE.PASSWORD;
  }

  onInput(): void {
    this.valueChange.emit(this.value);
    this.onChange(this.value); // this will notify the parent form of the value change
    this.onTouched(); // this will notify the parent form that the input field has been touched
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private onChange = (value: any) => {
    // console.log("onChange", value);
  };
  private onTouched = () => {
    // console.log("onTouched");
  };

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }


  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    const valueIsRequired = control.hasValidator(Validators.required);
    const requiredError = { required: true };

    if (!value && valueIsRequired) {
      return requiredError;
    }

    return null;
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
    // this.value = this.value || '';  // Initialize input here
  }
}

