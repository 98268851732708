<div class="d-flex p-2" [class]="position==='end' ? 'justify-content-start':'justify-content-end'">
  <span></span>
  <label *ngIf="!isMobile" [class.float-end]="!arrowToggle.checked" class="arrow-label no-select" for="arrow-checkbox"
    [id]="id">
    <input #arrowToggle (change)="toggleMinimize.emit()" type="checkbox" id="arrow-checkbox"
      [checked]="position ==='end' " />
      <div class="toggle-wrapper">
        <span [class]="arrowToggle.checked ? 'right' : 'left'" class="arrow--l-r">
          <span></span><span></span><span></span><span></span><span></span>
        </span>
      </div>
  </label>
  <label *ngIf="isMobile" class="arrow-label no-select" for="arrow-checkbox" [id]="id">
    <input (change)="sideNavClose.emit()" type="checkbox" id="arrow-checkbox" />
    <div class="toggle-wrapper">
      <span class="arrow--l-r" [class]="position === 'end' ? 'right': 'left'">
        <span></span><span></span><span></span><span></span><span></span>
      </span>
    </div>
  </label>
</div>
