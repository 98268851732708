import { Injectable } from '@angular/core';
import { ControlsConfig } from './controls-config.model';

@Injectable({
  providedIn: 'root',
})
export class ControlsConfigService {
  private config!: ControlsConfig;

  setConfig(config: ControlsConfig) {
    this.config = config;
  }

  updateConfig(config: ControlsConfig) {
    this.config = { ...this.config, ...config };
  }

  getConfig(): ControlsConfig {
    return this.config;
  }
}
