import {
  Directive,
  OnChanges,
  Input,
  SimpleChanges,
  HostBinding,
  OnInit,
  ElementRef,
  Renderer2,
} from '@angular/core';

import {
  BTN,
  COLOR,
  Color,
  FILL,
  SIZE,
  Shape,
  Size,
  Fill,
  TextTransform,
  TEXT_TRANSFORM,
} from '../constants';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Directive({
  selector: '[sch-button]',
  standalone: true,
})
export class ButtonDirective implements OnChanges, OnInit {
  constructor(private el: ElementRef, 
              private rdr: Renderer2, 
              private sanitizer: DomSanitizer) { }

  @Input() fill: Fill = FILL.SOLID;

  @HostBinding('attr.aria-label')
  @Input() description = '';

  @Input() shape: Shape = BTN.SHAPE.NORMAL;
  @Input() textTransform: TextTransform = TEXT_TRANSFORM.CAPITALIZE;
  @Input() color: Color | string = COLOR.PRIMARY;
  @Input() fontColor: Color | string = '#ffffff';
  @Input() size: Size = SIZE.MEDIUM;
  @Input() hasShadow = false;
  @Input() hideBorder = false;

  @HostBinding('class') hostElementClasses = this.getHostElementClasses();
  @HostBinding('style') get getHostElementStyle(): SafeStyle | null{
    if(!this.hideBorder){
      let style = `border: 1px solid`;
      if (!this.isColorLiteral(this.color)) {
        style = `${style}; border-color: ${this.color}`;
      }
      return this.sanitizer.bypassSecurityTrustStyle(style);
    }
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    this.hostElementClasses = this.getHostElementClasses();
  }

  ngOnInit(): void {
    this.rdr.setAttribute(this.el.nativeElement, 'type', 'button');
  }

  isColorLiteral(color: Color | string): boolean {
    const isColorLiteral = Object.values(COLOR).some(x => x === color);
    return isColorLiteral;
  }

  private getHostElementClasses(): string {
    let classes = `btn btn-${this.size} text-${this.textTransform}`;
    const element = this.el.nativeElement as HTMLElement;

    if (this.isColorLiteral(this.color) && !this.hideBorder) {
      classes = `${classes} border-${this.color}`;
    }
    if (this.fill === FILL.OUTLINE) {
      this.isColorLiteral(this.color) ? 
      classes = `${classes} text-${this.color}` : element.style.color = this.color;
    }
    else{
      this.isColorLiteral(this.color) ? 
      classes = `${classes} btn-${this.color}` :  element.style.backgroundColor = this.color;

      this.isColorLiteral(this.fontColor) ? 
      classes = `${classes} text-${this.fontColor}` : element.style.color = this.fontColor;
    }

    if (!this.hasShadow) {
      classes = `${classes} shadow-none`;
    }

    if (this.shape === BTN.SHAPE.CIRCLE) {
      classes = `${classes} btn-floating`;
    }

    if (this.shape === BTN.SHAPE.ROUNDED) {
      classes = `${classes} btn-${this.shape}`;
    }

    return classes;
  }
}
