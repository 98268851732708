import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ConnectedPosition,
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
  OverlayConfig,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { SharedTooltipComponent } from '../components';
import { fromEvent, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { Color, TooltipPosition } from '../constants';
@Directive({
  selector: '[sch-tooltip]',
  standalone: true,
})
export class SharedTooltipDirective implements OnInit, OnDestroy, OnChanges {
  @Input('sch-tooltip') tooltip = '';
  private tooltipDisabled = false;
  @Input() placement: TooltipPosition = 'top';
  @Input() html = false;
  @Input() animation = true;
  @Input() trigger = 'hover focus';
  @Input() delayShow = 0;
  @Input() delayHide = 0;
  @Input() offset = 0;

  @Input() tooltipColor?: Color;
  colorClass = '';

  @Output() tooltipShow = new EventEmitter();
  @Output() tooltipHide = new EventEmitter();

  overlayRef!: OverlayRef;
  tooltipRef: any;
  open = false;
  showTimeout: any = 0;
  hideTimeout: any = 0;
  destroy$ = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    this.colorClass = this.getHostElementClasses();
  }

  private getHostElementClasses(): string {
    return this.tooltipColor ? `bg-${this.tooltipColor}` : '';
  }

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private el: ElementRef
  ) {
    this.overlay = overlay;
    this.overlayPositionBuilder = overlayPositionBuilder;
    this.el = el;
  }

  ngOnInit() {
    if (this.tooltipDisabled || this.tooltip === '') {
      return;
    }
    this.bindTriggerEvents();
  }

  bindTriggerEvents() {
    const triggers = this.trigger.split(' ');
    triggers.forEach((trigger) => {
      if (trigger === 'click') {
        fromEvent(this.el.nativeElement, trigger)
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => this.toggle());
      } else if (trigger !== 'manual') {
        const evIn = trigger === 'hover' ? 'mouseenter' : 'focusin';
        const evOut = trigger === 'hover' ? 'mouseleave' : 'focusout';
        fromEvent(this.el.nativeElement, evIn)
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => this.show());
        fromEvent(this.el.nativeElement, evOut)
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => this.hide());
      }
    });
  }

  createOverlayConfig() {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.el)
      .withPositions(this.getPosition() as ConnectedPosition[]);
    const overlayConfig = new OverlayConfig({
      hasBackdrop: false,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy,
    });
    return overlayConfig;
  }

  createOverlay() {
    this.overlayRef = this.overlay.create(this.createOverlayConfig());
  }

  getPosition() {
    let position;
    const positionTop = {
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom',
      offsetY: -this.offset,
    };
    const positionBottom = {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
      offsetY: this.offset,
    };
    const positionRight = {
      originX: 'end',
      originY: 'center',
      overlayX: 'start',
      overlayY: 'center',
      offsetX: this.offset,
    };
    const positionLeft = {
      originX: 'start',
      originY: 'center',
      overlayX: 'end',
      overlayY: 'center',
      offsetX: -this.offset,
    };
    switch (this.placement) {
      case 'top':
        position = [positionTop, positionBottom];
        break;
      case 'bottom':
        position = [positionBottom, positionTop];
        break;
      case 'left':
        position = [positionLeft, positionRight];
        break;
      case 'right':
        position = [positionRight, positionLeft];
        break;
      default:
        break;
    }
    return position;
  }

  show() {
    if (this.hideTimeout || this.open) {
      this.overlayRef.detach();
      clearTimeout(this.hideTimeout);
      this.hideTimeout = null;
    }
    this.createOverlay();
    this.showTimeout = setTimeout(() => {
      const tooltipPortal = new ComponentPortal(SharedTooltipComponent);
      this.tooltipShow.emit(this);
      this.open = true;
      if (this.overlayRef && this.overlayRef.hasAttached()) {
        this.overlayRef.detach();
      }
      this.tooltipRef = this.overlayRef.attach(tooltipPortal);
      this.tooltipRef.instance.title = this.tooltip;
      this.tooltipRef.instance.html = this.html;
      this.tooltipRef.instance.animation = this.animation;
      this.tooltipRef.instance.animationState = 'visible';
      this.tooltipRef.instance.colorClass = this.colorClass;
      this.tooltipRef.instance.markForCheck();
    }, this.delayShow);
  }

  hide() {
    if (this.showTimeout) {
      clearTimeout(this.showTimeout);
      this.showTimeout = null;
    } else {
      return;
    }
    this.hideTimeout = setTimeout(() => {
      this.tooltipHide.emit(this);
      if (!this.tooltipRef) {
        this.overlayRef.detach();
        this.open = false;
      } else {
        this.tooltipRef.instance._hidden.pipe(first()).subscribe(() => {
          this.overlayRef.detach();
          this.open = false;
        });
        this.tooltipRef.instance.animationState = 'hidden';
        this.tooltipRef.instance.markForCheck();
      }
    }, this.delayHide);
  }

  toggle() {
    if (this.open) {
      this.hide();
    } else {
      this.show();
    }
  }

  ngOnDestroy() {
    if (this.open || this.showTimeout) {
      this.hide();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }
}
