import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
} from '@angular/core';
import { SIZE, FILL, Icon, COLOR, DIRECTION } from '@seech/shared-ng';
import { ButtonDirective } from '../../directives';
import { IconDirective, NormalRounded } from '@seech/shared-ng';

import { MdbDropdownModule } from 'mdb-angular-ui-kit/dropdown';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';

export interface DropDownItem{
  id?: string;
  label: string;
  value: string;
  icon?: Icon;
}

@Component({
  selector: 'seech-combo-button',
  standalone: true,
  imports: [
    CommonModule,
    ButtonDirective,
    IconDirective,
    MdbDropdownModule,
    MdbRippleModule,
  ],
  templateUrl: './combo-button.component.html',
  styleUrls: ['./combo-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComboButtonComponent {
  FILL = FILL;

  @Input() label = '';
  @Input() disabled = false;
  @Input() size = SIZE.MEDIUM;
  @Input() variant = FILL.SOLID;
  @Input() icon: Icon = 'love';
  @Input() color = COLOR.PRIMARY;
  @Input({ required: true }) id: string | number | null | undefined;
  @Input() dropdownItems: DropDownItem[] = [];
  @Input() shape: NormalRounded = 'normal';
  @Input() alignment = DIRECTION.DOWN;
  @Input() activeItem?: string;
  @Input() hideBorder = false;

  @Output() clicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() selected: EventEmitter<DropDownItem> = new EventEmitter<DropDownItem>();

  public get classes(): string[] {
    let color = `btn-${this.color}`;
    if (this.variant === FILL.OUTLINE) {
      color = `btn-${this.variant}-${this.color}`;
    }

    const classes = ['btn', color, `btn-${this.size}`];

    if (this.shouldDisplayDropdown()) {
      classes.push('combo-button');
    }

    return classes;
  }

  get menuAlignmentClasses(): string {
    return this.alignment === 'start' ? '' : `dropdown-menu-${this.alignment}`;
  }

  shouldDisplayDropdown(): boolean {
    return this.dropdownItems.length > 0;
  }

  onClick() {
    this.clicked.emit(true);
  }

  onListItemClicked(item: DropDownItem) {
    this.activeItem = item.value;
    this.selected.emit(item);
  }

  computeId(prefix: string): string | null {
    return '' + this.id ? `${prefix}-${this.id}` : null;
  }
}
