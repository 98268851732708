import { Injectable } from '@angular/core';
import { ControlsConfigService } from '../injection/controls-config.service';

@Injectable({
  providedIn: 'root',
})
export class LocationAutocompleteService {
  constructor(private controlsConfigService: ControlsConfigService) {}

  load(mapEnabled: boolean): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const apiKey = this.controlsConfigService.getConfig().googleAPIKey;

      if (!apiKey) {
        console.error('Google API Key not injected into controls-ng');
        reject('Google API Key not injected into controls-ng');
        return;
      }

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.defer = true;

      let libraries = 'places';
      if (mapEnabled) {
        libraries += ',drawing';
      }

      const scriptUrl = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=${libraries}`;

      // Use Trusted Types policy for setting the script URL
      try {
        if (window['trustedTypes'] && window['defaultTrustedTypesPolicy']) {
          script.src = window['defaultTrustedTypesPolicy'].createScriptURL(
            scriptUrl
          ) as unknown as string;
        } else {
          script.src = scriptUrl;
        }
      } catch (e) {
        console.error('Error using Trusted Types policy:', e);
        script.src = scriptUrl;
      }

      script.onload = () => {
        resolve();
      };

      script.onerror = (error: any) => {
        reject(error);
      };

      document.head.appendChild(script);
    });
  }
}
