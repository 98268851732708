<nav #navList class="mdc-deprecated-list">
  <a *ngFor="let item of navigationItems" mdbRipple class="mdc-deprecated-list-item" [routerLink]="item.route"
    [routerLinkActive]="getNavActiveClass()" id="{{item.id}}">
    <i *ngIf="item.icon" [sch-icon]="item.icon" [class]="iconClass" class="mdc-deprecated-list-item__graphic"
      aria-hidden="true"></i>
    <span class="mdc-deprecated-list-item__text" [class]="labelClass">
      {{item.label}}
    </span>
  </a>
</nav>
