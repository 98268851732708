import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MdbTooltipComponent } from 'mdb-angular-ui-kit/tooltip';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sch-tooltip',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tooltip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedTooltipComponent extends MdbTooltipComponent {
  colorClass = '';
}
