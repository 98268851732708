import { Directive, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidationService } from '../../services/validation.service';
import { CONTROL_TYPE } from '../../constants';

@Directive({
  selector: '[sch-input]',
  standalone: true,
})
export class InputDirective implements OnInit, OnChanges {

  @Input() type = CONTROL_TYPE.TEXT;
  @Input() input = '';
  @Input() label: string | null = null;

  @Output() inputChange: EventEmitter<string> = new EventEmitter<string>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  private formControl: FormControl = new FormControl();
  private labelElement: HTMLElement | null = null;

  constructor(
    private el: ElementRef,
    private validationService: ValidationService,
    private renderer: Renderer2,
  ) {
    this.el.nativeElement.addEventListener('input', this.onInput.bind(this));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['type']) {
      this.updateInputType();
    }
    if (changes['label']) {
      this.updateLabelElement();
      this.updateLabelClass();
    }
    if (changes['input']) {
      this.formControl.setValue(this.input);
      this.adjustLabelForValue(this.input !== '');
    }
  }

  private updateInputType() {
    this.renderer.setAttribute(this.el.nativeElement, 'type', this.type);
  }

  private updateLabelElement() {
    if (this.label) {
      this.labelElement = document.querySelector(this.label);
    } else {
      this.labelElement = null;
    }
  }

  private updateLabelClass() {
    if (this.labelElement) {
      this.renderer.addClass(this.labelElement, 'sch-input-label--has-label');
    } else {
     this.labelElement && this.renderer.removeClass(this.labelElement, 'sch-input-label--has-label');
    }
  }

  ngOnInit() {
    this.formControl = new FormControl('', this.validationService.getValidator(this.type));
    // Emit initial validation state and value
    this.isValid.emit(!this.formControl.errors);
    this.input = this.formControl.value;
    this.inputChange.emit(this.input);
    this.setValidationClass(!this.formControl.errors);

    if (this.type === CONTROL_TYPE.PASSWORD) {
      this.renderer.setAttribute(this.el.nativeElement, 'autocomplete', 'off');
      this.renderer.setAttribute(this.el.nativeElement, 'type', 'password');
    } else {
      this.renderer.setAttribute(this.el.nativeElement, 'autocomplete', 'on');
      this.renderer.setAttribute(this.el.nativeElement, 'type', 'text');
    }

    const hasValue = !!this.el.nativeElement.value;
    if (hasValue) {
      this.adjustLabelForValue(true);
    }

    const hasPlaceholder = this.el.nativeElement.hasAttribute('placeholder');

    if (hasPlaceholder && this.labelElement) {
      this.renderer.addClass(this.labelElement, 'sch-input-label--has-placeholder');
    } else if (this.labelElement) {
      this.renderer.removeClass(this.labelElement, 'sch-input-label--has-placeholder');
    }

    // Update label class initially
    this.updateLabelClass();
  }

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;
    this.formControl.setValue(inputValue);
    const validationStatus = !this.formControl.errors;

    this.isValid.emit(validationStatus);
    this.input = inputValue;
    this.inputChange.emit(this.input);  // Emit the input value
    this.setValidationClass(validationStatus);
    this.adjustLabelForValue(this.el.nativeElement.value !== '');
  }

  private setValidationClass(isValid: boolean): void {
    if (!this.el.nativeElement.value) return;

    this.adjustLabelForValue(this.el.nativeElement.value !== '');
  }

  private adjustLabelForValue(hasValue: boolean): void {
    if (this.labelElement) {
      if (hasValue) {
        this.renderer.addClass(this.labelElement, 'sch-input-label--has-value');
      } else {
        this.renderer.removeClass(this.labelElement, 'sch-input-label--has-value');
      }
    }
   }
  }

