/* eslint-disable @angular-eslint/no-output-on-prefix */
import { AnimationEvent } from '@angular/animations';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ANIMATION_MODULE_TYPE,
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  Optional,
  Output,
  QueryList,
  ViewChild,
  ViewEncapsulation,
  forwardRef,
  isDevMode,
} from '@angular/core';
import { merge, Subject } from 'rxjs';
import {
  filter,
  take,
  takeUntil,
  debounceTime,
  startWith,
} from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';
import { CdkScrollable, ViewportRuler } from '@angular/cdk/scrolling';
import { Directionality } from '@angular/cdk/bidi';
import {
  SEECH_LAYOUT_CONTAINER,
  throwMatDuplicatedLayoutError,
} from '../../layout.token';
import { SideNavComponent } from '../../components/side-nav/side-nav.component';
import { LayoutContentComponent } from '../../components/layout-content/layout-content.component';
import { ContentMargin } from '../../content-margin.interface';

@Component({
  selector: 'seech-layout-container',
  exportAs: 'seechLayoutContainer',
  templateUrl: './layout-container.component.html',
  styleUrls: ['../layout/layout.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: SEECH_LAYOUT_CONTAINER,
      useExisting: LayoutContainerComponent,
    },
  ],
})
export class LayoutContainerComponent
  implements AfterContentInit, OnDestroy, ContentMargin
{
  @Input() disableFullHeight = false;

  @HostBinding('attr.ngSkipHydration') ngSkipHydrationAttribute = '';

  @HostBinding('class') get container() {
    return 'seech-layout-container';
  }

  @HostBinding('class.seech-layout-container-explicit-backdrop')
  get backdrop() {
    return this.backdropOverride;
  }

  @ContentChildren(forwardRef(() => SideNavComponent), {
    descendants: true,
  })
  sideNavComponents = new QueryList<SideNavComponent>();

  @ContentChild(LayoutContentComponent)
  content?: LayoutContentComponent;
  @ViewChild(LayoutContentComponent)
  userContent?: LayoutContentComponent;

  get start(): SideNavComponent | null {
    return this._start;
  }

  get end(): SideNavComponent | null {
    return this._end;
  }

  @Input()
  get hasBackdrop(): boolean {
    if (this.backdropOverride == null) {
      return (
        !this._start ||
        this._start.mode !== 'side' ||
        !this._end ||
        this._end.mode !== 'side'
      );
    }

    return this.backdropOverride;
  }
  set hasBackdrop(value: BooleanInput) {
    this.backdropOverride = value == null ? null : coerceBooleanProperty(value);
  }
  backdropOverride: boolean | null = null;

  /** Event emitted when the layout backdrop is clicked. */
  @Output() readonly backdropClick: EventEmitter<void> =
    new EventEmitter<void>();

  /** The layout at the start/end position, independent of direction. */
  private _start: SideNavComponent | null = null;
  private _end: SideNavComponent | null = null;

  private left: SideNavComponent | null = null;
  private right: SideNavComponent | null = null;

  private readonly destroyed = new Subject<void>();

  private readonly doCheckSubject = new Subject<void>();

  contentMargins: { left: number | null; right: number | null } = {
    left: null,
    right: null,
  };

  readonly contentMarginChanges = new Subject<{
    left: number | null;
    right: number | null;
  }>();

  /** Reference to the CdkScrollable instance that wraps the scrollable content. */
  get scrollable(): CdkScrollable {
    const cdkScrollable: any = this.userContent || this.content;
    return cdkScrollable;
  }
  constructor(
    @Optional() private dir: Directionality,
    private element: ElementRef<HTMLElement>,
    private ngZone: NgZone,
    private cdRef: ChangeDetectorRef,
    public breakpointObserver: BreakpointObserver,
    viewportRuler: ViewportRuler,
    @Optional() @Inject(ANIMATION_MODULE_TYPE) private animationMode?: string
  ) {
    if (dir) {
      dir.change.pipe(takeUntil(this.destroyed)).subscribe(() => {
        this.validateLayouts();
        this.updateContentMargins();
      });
    }

    viewportRuler
      .change()
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => this.updateContentMargins());
  }

  ngAfterContentInit() {
    this.sideNavComponents?.changes
      .pipe(startWith(this.sideNavComponents), takeUntil(this.destroyed))
      .subscribe((layout: QueryList<SideNavComponent>) => {
        this.sideNavComponents.reset(
          layout.filter((item) => !item.container || item.container === this)
        );
        this.sideNavComponents.notifyOnChanges();
      });

    this.sideNavComponents.changes.pipe(startWith(null)).subscribe(() => {
      this.validateLayouts();

      this.sideNavComponents.forEach((layout: SideNavComponent) => {
        this.watchLayoutToggle(layout);
        this.watchLayoutPosition(layout);
        this.watchLayoutMode(layout);
      });

      if (
        !this.sideNavComponents.length ||
        this.isLayoutOpen(this._start) ||
        this.isLayoutOpen(this._end)
      ) {
        this.updateContentMargins();
      }

      this.cdRef.markForCheck();
    });

    // Avoid hitting the NgZone through the debounce timeout.
    this.ngZone.runOutsideAngular(() => {
      this.doCheckSubject
        .pipe(debounceTime(10), takeUntil(this.destroyed))
        .subscribe(() => this.updateContentMargins());
    });
  }

  ngOnDestroy() {
    this.contentMarginChanges.complete();
    this.doCheckSubject.complete();
    this.sideNavComponents.destroy();
    this.destroyed.next();
    this.destroyed.complete();
  }

  /** Calls `open` of both start and end layouts */
  open(): void {
    this.sideNavComponents.forEach((sidenavComponent) =>
      sidenavComponent.open()
    );
  }

  /** Calls `close` of both start and end layouts */
  close(): void {
    this.sideNavComponents.forEach((sidenavComponent) =>
      sidenavComponent.close()
    );
  }

  updateContentMargins() {
    let left: number | any = 0;
    let right: number | any = 0;

    if (this.left && this.left.opened) {
      if (this.left.mode == 'side') {
        left += this.left.getWidth();
      } else if (this.left.mode == 'push') {
        const width = this.left.getWidth();
        left += width;
        right -= width;
      }
    }

    if (this.right && this.right.opened) {
      if (this.right.mode == 'side') {
        right += this.right.getWidth();
      } else if (this.right.mode == 'push') {
        const width = this.right.getWidth();
        right += width;
        left -= width;
      }
    }

    left = left || undefined;
    right = right || undefined;

    if (
      left !== this.contentMargins.left ||
      right !== this.contentMargins.right
    ) {
      this.contentMargins = { left, right };
      this.ngZone.run(() => {
        this.contentMarginChanges.next(this.contentMargins);
      });
    }
  }

  private watchLayoutToggle(layout: SideNavComponent): void {
    layout.animationStarted
      .pipe(
        filter((event: AnimationEvent) => event.fromState !== event.toState),
        takeUntil(this.sideNavComponents.changes)
      )
      .subscribe((event: AnimationEvent) => {
        // Set the transition class on the container so that the animations occur. This should not
        // be set initially because animations should only be triggered via a change in state.
        if (
          event.toState !== 'open-instant' &&
          this.animationMode !== 'NoopAnimations'
        ) {
          this.element.nativeElement.classList.add('seech-layout-transition');
        }

        this.updateContentMargins();
        this.cdRef.markForCheck();
      });

    if (layout.mode !== 'side') {
      layout.openedChange
        .pipe(takeUntil(this.sideNavComponents.changes))
        .subscribe(() => this.setContainerClass(layout.opened));
    }
  }

  private watchLayoutPosition(layout: SideNavComponent): void {
    if (!layout) {
      return;
    }
    layout.onPositionChanged
      .pipe(takeUntil(this.sideNavComponents.changes))
      .subscribe(() => {
        this.ngZone.onMicrotaskEmpty.pipe(take(1)).subscribe(() => {
          this.validateLayouts();
        });
      });
  }

  private watchLayoutMode(layout: SideNavComponent): void {
    if (layout) {
      layout.modeChanged
        .pipe(takeUntil(merge(this.sideNavComponents.changes, this.destroyed)))
        .subscribe(() => {
          this.updateContentMargins();
          this.cdRef.markForCheck();
        });
    }
  }

  private setContainerClass(isAdd: boolean): void {
    const classList = this.element.nativeElement.classList;
    const className = 'seech-layout-container-has-open';

    if (isAdd) {
      classList.add(className);
    } else {
      classList.remove(className);
    }
  }

  private validateLayouts() {
    this._start = this._end = null;

    // Ensure that we have at most one start and one end layout.
    this.sideNavComponents.forEach((sidenavComponent) => {
      if (sidenavComponent.position == 'end') {
        if (
          this._end != null &&
          (typeof isDevMode() === 'undefined' || isDevMode())
        ) {
          throwMatDuplicatedLayoutError('end');
        }
        this._end = sidenavComponent;
      } else {
        if (
          this._start != null &&
          (typeof isDevMode() === 'undefined' || isDevMode())
        ) {
          throwMatDuplicatedLayoutError('start');
        }
        this._start = sidenavComponent;
      }
    });

    this.right = this.left = null;

    // Detect if we're LTR or RTL.
    if (this.dir && this.dir.value === 'rtl') {
      this.left = this._end;
      this.right = this._start;
    } else {
      this.left = this._start;
      this.right = this._end;
    }
  }

  /** Whether the container is being pushed to the side by one of the layouts. */
  private isPushed() {
    return (
      (this.isLayoutOpen(this._start) && this._start.mode != 'over') ||
      (this.isLayoutOpen(this._end) && this._end.mode != 'over')
    );
  }

  onBackdropClicked() {
    this.backdropClick.emit();
    this.closeModalLayoutsViaBackdrop();
  }

  closeModalLayoutsViaBackdrop() {
    // Close all open layouts where closing is not disabled and the mode is not `side`.
    [this._start, this._end]
      .filter(
        (sidenavComponent) =>
          sidenavComponent &&
          !sidenavComponent.disableClose &&
          this.canHaveBackdrop(sidenavComponent)
      )
      .forEach((sidenavComponent: any) =>
        sidenavComponent.closeViaBackdropClick()
      );
  }

  isShowingBackdrop(): boolean {
    return (
      (this.isLayoutOpen(this._start) && this.canHaveBackdrop(this._start)) ||
      (this.isLayoutOpen(this._end) && this.canHaveBackdrop(this._end))
    );
  }

  private canHaveBackdrop(sidenavComponent: SideNavComponent): boolean {
    return sidenavComponent.mode !== 'side' || !!this.backdropOverride;
  }

  private isLayoutOpen(
    sidenavComponent: SideNavComponent | null
  ): sidenavComponent is SideNavComponent {
    return sidenavComponent != null && sidenavComponent.opened;
  }
}
