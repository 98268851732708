import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  forwardRef,
} from '@angular/core';

import { CommonModule } from '@angular/common';

import { MdbCheckboxModule } from 'mdb-angular-ui-kit/checkbox';

import { Color, COLOR } from '@seech/shared-ng';

import { ThemeColorDirective } from '../../directives';

import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { ControlsConfigService } from '../../injection/controls-config.service';
import { SwitchVariant } from '../../injection/controls-config.model';

@Component({
  selector: 'seech-switch',

  standalone: true,

  imports: [CommonModule, MdbCheckboxModule, ThemeColorDirective],

  templateUrl: './switch.component.html',

  styleUrls: ['./switch.component.scss'],

  providers: [
    {
      provide: NG_VALUE_ACCESSOR,

      useExisting: forwardRef(() => SwitchComponent),

      multi: true,
    },

    {
      provide: NG_VALIDATORS,

      useExisting: forwardRef(() => SwitchComponent),

      multi: true,
    },
  ],
})
export class SwitchComponent implements ControlValueAccessor, Validator, OnChanges {
  @Input() label = '';

  @Input() alignLabel: 'left' | 'right' = 'right';

  @Input() selected = false;

  @Input() disabled = false;

  @Input() id = '';

  @Input() name = '';

  @Input() variant!: SwitchVariant;

  @Input() color: Color = COLOR.PRIMARY;

  @Output() selectedChange: any = new EventEmitter<boolean>();

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  private onChange = (value: any) => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouched = () => {};

  constructor(private elRef: ElementRef, private configService: ControlsConfigService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(!changes['variant'] && !this.variant) {
      const config = this.configService.getConfig();
      this.variant = config.switchVariant ? config.switchVariant : 'modern';
    }
  }

  onSwitchChange(event: Event): void {
    this.selected = (event.target as HTMLInputElement).checked;

    this.selectedChange.emit(this.selected);
    this.onChange(this.selected);
    this.onTouched();
  }

  writeValue(value: any): void {
    this.selected = !!value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validate(control: AbstractControl): ValidationErrors | null {
    // return this.selected ? null : { required: true };
    const valueIsRequired = control.hasValidator(Validators.required);
    const requiredError = { required: true };
    if (!this.selected && valueIsRequired) {
      return requiredError;
    }

    return null;
  }
}
