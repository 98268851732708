<div id="{{ id }}" class="seech-checkbox">
  <div class="form-check form-checkbox" sch-color [color]="color">
    <input
      mdbCheckbox
      class="form-check-input {{shape}}"
      [type]="type"
      id="{{ id }}"
      name="{{ name }}"
      [(ngModel)]="selected"
      [value]="value"
      [disabled]="disabled"
      (change)="onCheckboxChange()"
      />
  </div>

  <div class="seech-checkbox-label d-flex align-items-center" *ngIf="label">
    <ng-content></ng-content>
    <label class="form-check-label {{selected ? 'text-'+color : ''}}" for="{{ name }}">
      {{ label }}
    </label>
  </div>
</div>
