import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'seech-step',
  standalone: true,
  imports: [CommonModule],
  template: ` <ng-content *ngIf="active"></ng-content> `,
  styleUrls: ['./stepper.component.scss'],
})
export class StepComponent {
  @Input() active = false;
  @Input() name = '';
}
