import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'controls',
    loadChildren: () =>
      import('./modules/controls/controls.module').then(
        (x) => x.ControlsModule
      ),
  },
  {
    path: 'ux',
    loadChildren: () =>
      import('./modules/ux/ux.module').then((x) => x.UxModule),
  },
  {
    path: 'visualization',
    loadChildren: () =>
      import('./modules/visualization/visualization.module').then(
        (x) => x.VisualizationModule
      ),
  },
  {
    path: 'layout',
    loadChildren: () =>
      import('./modules/layout/layout.module').then((x) => x.LayoutModule),
  },
  {
    path: 'media',
    loadChildren: () =>
      import('./modules/media/media.module').then((x) => x.MediaModule),
  },
  {
    path: 'icons',
    loadChildren: () =>
      import('./modules/icons/icons.module').then((x) => x.IconsModule),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'controls',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
