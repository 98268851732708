import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MdbDropdownModule } from 'mdb-angular-ui-kit/dropdown';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { NgIf } from '@angular/common';
import { ButtonDirective, IconDirective } from '../../directives';
import { COLOR, Color, DIRECTION, FILL, Icon, Shape, SIZE } from '../../constants';

@Component({
  selector: 'seech-shared-dropdown',
  standalone: true,
  imports: [
    MdbDropdownModule,
    MdbRippleModule,
    ButtonDirective,
    NgIf,
    IconDirective,
  ],
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnChanges {
  @Input() disabled = false;
  @Input() size = SIZE.MEDIUM;
  @Input() direction = DIRECTION.DOWN;
  @Input() alignment = DIRECTION.DOWN;
  @Input() color: Color | string = COLOR.PRIMARY;
  @Input() variant = FILL.SOLID;
  @Input() hasShadow = false;
  @Input() showDropDownIcon = true;
  @Input() hideBorder = false;
  @Input() label = '';
  @Input() icon: Icon | undefined = undefined;
  @Input() shape: Shape = 'normal';
  @Input({ required: true }) id: string | number | null | undefined;
  @Input() fontColor?: Color | string;
  @Output() mainBtnClick = new EventEmitter<void>();
  menuAlignmentClasses = this.getMenuAlignmentClasses();
  @HostBinding('class') hostElementClasses = this.getHostElementClasses();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    // this.classes = this.getButtonClasses();
    this.hostElementClasses = this.getHostElementClasses();
    this.menuAlignmentClasses = this.getMenuAlignmentClasses();
  }

  isColorLiteral(color: Color | string): boolean {
    const isColorLiteral = Object.values(COLOR).some(x => x === color);
    return isColorLiteral;
  }

  computeId(prefix: string): string | null {
    return '' + this.id ? `${prefix}-${this.id}` : null;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClick() {}
  private getHostElementClasses(): string {
    return this.direction === 'down' ? '' : `drop${this.direction}`;
  }

  private getMenuAlignmentClasses(): string {
    return this.alignment === 'start' ? '' : `dropdown-menu-${this.alignment}`;
  }
}
