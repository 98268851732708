import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { SEECH_LAYOUT_CONTAINER } from '../../layout.token';
import { LayoutContainerComponent } from '../layout/layout-container.component';
import { ContentMargin } from '../../content-margin.interface';

@Component({
  selector: 'seech-standard-layout',
  exportAs: 'seechStandardLayout',
  templateUrl: './standard-layout.component.html',
  styleUrls: ['../layout/layout.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: SEECH_LAYOUT_CONTAINER,
      useExisting: StandardLayoutComponent,
    },
  ],
})
export class StandardLayoutComponent
  extends LayoutContainerComponent
  implements OnChanges, OnDestroy, ContentMargin
{
  @HostBinding('attr.ngSkipHydration') _ngSkipHydrationAttribute = '';
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    this._containerClass = this.getContainerClass();
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
  override ngOnDestroy(): void {}

  @HostBinding('class') _containerClass = this.getContainerClass();

  getContainerClass() {
    return this.disableFullHeight
      ? 'seech-layout-container'
      : 'seech-layout-container seech-sidenav-container';
  }

  @HostBinding('class.seech-layout-container-explicit-backdrop')
  get _backdrop() {
    return this.backdropOverride;
  }
}
