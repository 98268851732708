import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MdbPopconfirmRef } from 'mdb-angular-ui-kit/popconfirm';
import { ButtonDirective, COLOR, Color } from '@seech/shared-ng'

@Component({
  selector: 'seech-confirmation-dialog',
  standalone: true,
  imports: [CommonModule, ButtonDirective],
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  @Input() title?: string;
  @Input() cancelLabel = "Cancel";
  @Input() confirmLabel = "Confirm";
  @Input() confirmBtnColor: Color = COLOR.PRIMARY;
  @Input() cancelBtnColor: Color = COLOR.DANGER;
  @Output() confirm: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private popconfirmRef: MdbPopconfirmRef<ConfirmationDialogComponent>){}

  onConfirm(verdict: boolean){
    this.confirm.emit(verdict);
    this.popconfirmRef.close();
  }
}
