<div class="sch-input" [attr.readonly]="readonly" [attr.disabled]="disabled">
  <label *ngIf="label" class="sch-input-label" for="{{ id }}">
    {{ label }}
  </label>

  <div (click)="handleViewDropDown(!showDropdown)">
    <div class="display-panel position-relative">
      <div
        class="placeholder-and-value sch-input-control"
        [class.focused]="showDropdown"
      >
        <p *ngIf="!selectedValue.countryCode">Select currency</p>
        <div
          *ngIf="selectedValue.countryCode"
          class="d-flex w-full justify-content-=start align-items-center"
        >
          <div class="sch-select-option-icon">
            <img
              src="https://flagcdn.com/{{
                selectedValue.countryCode | lowercase
              }}.svg"
              class="flags selected"
              alt=""
            />
          </div>
          <div class="sch-select-option-text preview-selected">
            <span
              class="text-wrapper"
              [innerHTML]="
                selectedValue.currencyName +
                ' (' +
                selectedValue.currencySymbol +
                ' ' +
                selectedValue.currencyCode +
                ')'
              "
            >
            </span>
          </div>
        </div>
      </div>

      <i
        [id]="id"
        *ngIf="!(readonly || disabled)"
        [sch-icon]="showDropdown ? 'expand' : 'collapse'"
        class="sch-input-icon sch-select-icn"
      ></i>
    </div>
  </div>

  <div
    *ngIf="showDropdown"
    class="sch-select-dropdown"
    #dropdownMenu
    sch-scroll-end-notifier
    [offset]="threshold"
    (scrollEnd)="onScrollEnd()"
  >
    <div class="input border-bottom">
      <i [sch-icon]="'search'" class="sch-currency-search-icon"></i>
      <input
        type="text"
        placeholder="Search for currency"
        autocomplete="{{ autocomplete }}"
        (input)="onInput($event)"
        value="{{ selectedValue?.currencyCode || '' }}"
        [readOnly]="disabled"
      />
    </div>

    <!-- <ng-container> -->
    <div *ngIf="options.length > 0" class="sch-select-option border-bottom">
      <ng-container>
        <div
          *ngFor="let option of options; index as i"
          class="flag-display border-bottom"
          [ngClass]="{
            active: option.countryCode === selectedValue.countryCode,
            'ml-width': variation === 'multi-line',
            'sl-width': variation === 'single-line'
          }"
          (click)="selectOption(option, i)"
        >
          <div class="sch-select-option-icon">
            <img
              src="https://flagcdn.com/{{ option.countryCode | lowercase }}.svg"
              class="flags"
              alt=""
            />
          </div>
          <ng-container *ngIf="variation === 'single-line'">
            <span class="currency-name sch-select-option-text">{{
              option.currencyName
            }}</span>
            &nbsp;
            <span
              class="sch-select-option-text"
              [innerHTML]="
                '(' + option.currencySymbol + ' ' + option.currencyCode + ')'
              "
            ></span>
          </ng-container>

          <div
            *ngIf="variation === 'multi-line'"
            class="sch-select-option-text multi-line"
          >
            <span>{{ option.currencyCode }}</span>
            <span>{{ option.currencyName }}</span>
          </div>
        </div>
      </ng-container>
    </div>
    <!-- </ng-container> -->
  </div>
</div>
