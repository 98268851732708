import { NgModule } from '@angular/core';
import { MdbModalModule } from 'mdb-angular-ui-kit/modal';
import { ModalService } from '../services';

@NgModule({
  imports: [MdbModalModule], // Import MdbModalModule here
  providers: [ModalService], // Provide the custom service
  exports:[MdbModalModule]
})
export class ModalModule {}
