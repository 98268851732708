<div
  class="sch-alert alert alert-dismissible fade show my-1" [style.height]="height"
  [class]="classes"
  [ngClass]="{
    'alert-success': type === 'success',
    'alert-danger': type === 'error',
    'alert-info': type === 'info',
    'alert-warning': type === 'warning',
    'none-banners': !notificationRef?.config?.position?.includes('center') || width === '',
    'with-banners': notificationRef?.config?.position?.includes('center') || width !== ''
  }"
  role="alert"
  id="{{id}}"
>

<i *ngIf="titleIcon" [sch-icon]="titleIcon" class="desc-icon"></i>

<div>
  <p class="mb-0 title">
    <ng-container>{{ title }}</ng-container>
  </p>
  <p class="mb-0 message">
      <ng-container>{{ message }}</ng-container>
  </p>
</div>

<!-- <div (click)="close(); onAlertClose()" class="ms-2" role="button">
  <i [sch-icon]="'cancel'" class="cancel-icon"></i>
</div> -->
</div>

