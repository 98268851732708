import {
  Directive
} from '@angular/core';
import {
  SharedTooltipDirective,
} from '@seech/shared-ng';
@Directive({
  selector: '[sch-tooltip]',
  standalone: true,
})
export class TooltipDirective extends SharedTooltipDirective {}
