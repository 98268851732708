import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  forwardRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MdbDatepickerModule } from 'mdb-angular-ui-kit/datepicker';
import { DateComponent, DateFormat } from '../date/date.component';
import { TimeComponent } from '../time/time.component';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';
import { ValidationService } from '../../services/validation.service';
import { IconDirective } from '@seech/shared-ng';
import { InputDirective } from '../../directives';


interface DateTimeValue {
  timeControl: any;
  dateControl: any;
}

export interface DateTimeFormat {
  date: DateFormat;
  time: '12' | '24';
}

@Component({
  selector: 'seech-date-time',
  standalone: true,
  imports: [
    CommonModule,
    MdbDatepickerModule,
    DateComponent,
    TimeComponent,
    ReactiveFormsModule,
    MdbFormsModule,
    IconDirective,
    InputDirective
  ],
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimeComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DateTimeComponent),
      multi: true,
    },
  ],
})
export class DateTimeComponent
  implements ControlValueAccessor, OnInit, OnDestroy, Validator {
  form!: FormGroup;
  isDateClosed = false;
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() selectedDate: any = new Date().toLocaleDateString()
  @Input() minDate: any = '';
  @Input() maxDate: any = '';
  @Input() datePlaceholder = 'Select date';
  @Input() dateRequired = false;
  @Input() inlineDate = false;
  @Input() dateId!: string;
  @Input() dateFormat: DateFormat = 'mm/dd/yyyy';
  @Input() disableDatePast = false;
  @Input() disableDateFuture = false;

  @Input() selectedTime: any = '';
  @Input() timeId!: string;
  @Input() timePlaceholder = 'Select a time';
  @Input() inlineTime = false;
  @Input() format24 = false;
  @Input() maxTime: any = null;
  @Input() minTime: any = null;
  @Input() id = ""
  @Input() label?: string;
  @Input() value = "";
  @Input() format: DateTimeFormat = {
    date: 'mm/dd/yyyy',
    time: '12'
  }
  @Input() autocomplete: 'on' | 'off' = 'off';

  @Output() dateSelected: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() dateClosed: EventEmitter<void> = new EventEmitter<void>();
  @Input() dateOpened: EventEmitter<void> = new EventEmitter<void>();

  @Output() timeOpened: EventEmitter<void> = new EventEmitter<void>();
  @Output() timeClosed: EventEmitter<void> = new EventEmitter<void>();
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() timeSelected: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() valid: EventEmitter<boolean> | any = new EventEmitter<boolean>();

  private destroy$ = new Subject<void>();

  dateTimeValue = "";
  showDateIcon = true;

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  private onChange = (value: any) => { };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouched = () => { };

  private privateValue: DateTimeValue | null = null;

  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private validationService: ValidationService
  ) { 
    this.showDateIcon = !this.disabled;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      timeControl: new FormControl(),
      dateControl: new FormControl(),
    });
    /**
     * Subscribing to valueChanges observable from parent form
     * and also cleaning up the subscription when components get destroyed
     * to prevent memory leak
     */
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.privateValue = value;
      this.onChange(value);
      this.onTouched();
    });
  }

  isValidDateTime = true;
  getPlaceholder(): string {
    const dateTimeFormat = {
      date: this.format.date,
      time: this.format.time === '12' ? 'HH:MM AM/PM' : 'HH:MM',
    }
    return `${dateTimeFormat.date} ${dateTimeFormat.time}`;
  }

  formatDateTime(event: any): void {
    const inputElement = event.target as HTMLInputElement;
    let formattedInput = inputElement.value.replace(/[^0-9:/APMapm\s]/g, '');

    // Function to handle backspace for date and time
    const handleBackspace = (input: string) => {
      // If backspace is hit and there's a separator or space at the end, remove it
      if (event.key === 'Backspace') {
        if (input?.endsWith('/') || input?.endsWith(' ') || input?.endsWith(':') || input?.endsWith('')) {
          return input?.slice(0, -1);
        }
      }
      return input;
    };

    // Apply backspace logic
    formattedInput = handleBackspace(formattedInput);

    // Handle date separators
    if (formattedInput.length > 2 && formattedInput.charAt(2) !== '/') {
      formattedInput = `${formattedInput.slice(0, 2)}/${formattedInput.slice(2)}`;
    }
    if (formattedInput.length > 5 && formattedInput.charAt(5) !== '/') {
      formattedInput = `${formattedInput.slice(0, 5)}/${formattedInput.slice(5)}`;
    }

    // Handle space after year
    if (formattedInput.length === 10 && !formattedInput.endsWith(' ')) {
      if (event.key === 'Backspace') {
        // remove space
        formattedInput = formattedInput.slice(0, -1);
      } else {
        formattedInput += ' ';
      }
    }

    // Handle time separator and AM/PM for 12-hour format
    if (formattedInput.length > 13 && formattedInput.charAt(13) !== ':') {
      formattedInput = `${formattedInput.slice(0, 13)}:${formattedInput.slice(13)}`;
    }

    // Handle AM/PM spacing for 12-hour format
    if (!this.format24) {
      // Remove any spaces before AM or PM
      formattedInput = formattedInput.replace(/\s*(AM|PM)/gi, ' $1');

      // Ensure there is exactly one space before AM or PM
      if (!/\s(AM|PM)$/.test(formattedInput)) {
        formattedInput = formattedInput.replace(/(AM|PM)$/gi, ' $1');
      }
    }

    // Convert AM/PM to uppercase and ensure a space before it
    formattedInput = formattedInput.replace(/(am|pm)$/i, (match) => ` ${match.toUpperCase()}`);
    formattedInput = formattedInput.replace(/\s{2,}(AM|PM)/, ' $1'); // Ensure only one space before AM or PM

    // Restrict the length based on the format
    const maxLength = this.format24 ? 16 : 21; // "dd/MM/YYYY HH:MM" or "dd/MM/YYYY HH:MM AM"
    if (formattedInput.length > maxLength) {
      formattedInput = formattedInput.substring(0, maxLength);
    }

    inputElement.value = formattedInput;
    this.value = formattedInput;

    this.valueChange.emit(formattedInput);
    this.onChange(formattedInput);
    this.onTouched();
    this.getDateTimeValidity()
    this.cdr.detectChanges();

    // Validate the date-time format
    if (!this.getDateTimeValidity()) {
      this.isValidDateTime = false;
      this.valid.emit(false);
    } else {
      this.isValidDateTime = true;
      this.valid.emit(true);
    }//console.log(this.isValidDateTime, this.getDateTimeValidity())


    // Update form controls
    const dateTimeParts = formattedInput.trim().split(' ');
    this.form.get('dateControl')?.setValue(dateTimeParts[0]);
    if (this.format24) {
      this.form.get('timeControl')?.setValue(dateTimeParts[1]);
    } else {
      this.form.get('timeControl')?.setValue(dateTimeParts.slice(1).join(' '));
    }
    this.valueChange.emit(this.value);
    this.getDateTimeValidity()
    this.cdr.detectChanges();
  }



  private formatAndValidateDateTime(): void {
    const dateValue = this.form.value.dateControl || '';
    const timeValue = this.format24
      ? this.form.value.timeControl
      : `${this.form.value.timeControl?.h || '00'}:${this.form.value.timeControl?.m || '00'} ${this.form.value.timeControl?.ampm || 'AM'}`;

    this.dateTimeValue = `${dateValue} ${timeValue}`.trim();
    this.isValidDateTime = this.getDateTimeValidity();
    this.valid.emit(this.isValidDateTime);
    this.valueChange.emit(this.dateTimeValue);
    this.onChange(this.dateTimeValue);
  }



  private getDateTimeValidity(): boolean {
    // Split the value into parts
    const valueParts = this.value.split(' ');
    const dateFromValueString = valueParts[0];
    let timeFromValueString = '';

    // Reconstruct the time string, including AM/PM if present
    if (this.format.time === '12') {
      // For 12-hour format, time string might include AM/PM
      timeFromValueString = valueParts.slice(1).join(' ');
    } else {
      // For 24-hour format, time string is just the second part
      timeFromValueString = valueParts[1] || '';
    }

    // Construct the dateTimeString object
    const dateTimeString = {
      date: this.form.value.dateControl || dateFromValueString,
      time: this.form.value.timeControl || timeFromValueString
    };

    // Validate the date and time
    return this.validationService.validateDateTime(dateTimeString, this.format);
  }


  triggerTime() {
    this.timeOpened.emit()
  }
  onDateOpened(e: any) {
    // this.isDateOpened =e;
    this.dateOpened.emit(e);
  }

  onDateSelected(e: any) {
    this.dateSelected.emit(e);
  }

  onDateClosed(e: any) {
    this.isDateClosed = e;
    this.dateClosed.emit(e);
    this.triggerTime();
    this.formatAndValidateDateTime();
  }

  private timeValue: any;
  onTimeChanged(e: any) {
    this.timeSelected.emit(e);
    this.timeValue = e;
  }

  onTimeOpen(e: any) {
    this.timeOpened.emit(e);
  }

  onTimeClosed(e: any) {
    this.timeClosed.emit(e);
    this.value = `${this.form.value.dateControl || 0} ${this.timeValue || 0}`;
    this.onChange(this.value);
    this.valueChange.emit(this.value);
    this.getDateTimeValidity();
    this.isValidDateTime = this.getDateTimeValidity();
    this.formatAndValidateDateTime();
    // console.log(this.isValidDateTime, this.getDateTimeValidity())
    // console.log(this.isValidDateTime, this.getDateTimeValidity())
    this.cdr.detectChanges();
  }

  writeValue(obj: any): void {
    this.privateValue = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled || this.disabled;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('focusout')
  onFocusOut() {
    this.onTouched();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validate(control: AbstractControl): ValidationErrors | null {
    // Check if the field is required and the value is empty
    if (!this.value) {
      return { required: true };
    }

    // Check if the date-time value is valid
    if (!this.getDateTimeValidity()) {
      return { invalidDateTime: true };
    }

    // If no validation errors, return null
    return null;
  }

}
