<div
  class="control-wrapper phone-control"
  [attr.readonly]="readonly"
  [attr.disabled]="disabled"
  [attr.inputFocused]="inputFocused || dropdownOpen"
>
  <div class="flex-grow-1">
    <ng-container *ngIf="phoneLabel; else phoneTemplate">
      <label
        class="sch-input-label classic"
        [attr.readonly]="readonly"
        [attr.disabled]="disabled"
      >
        {{ phoneLabel }}
      </label>
    </ng-container>

    <ng-template #phoneTemplate>
      <ng-content select="[phoneLabel]"></ng-content>
    </ng-template>

    <div class="phone-group" [class]="hostElementClasses" #phoneGroup>
      <div
        class="info" [ngClass]="{'d-none': (disabled || readonly) && !controlValue.countryCode}"
        (click)="disabled || readonly ? null : (dropdownOpen = !dropdownOpen)"
        cdkOverlayOrigin
        #trigger="cdkOverlayOrigin"
      >
        <img
          *ngIf="controlValue.countryCode"
          src="https://flagcdn.com/{{
            controlValue.countryCode | lowercase
          }}.svg"
          class="flag"
          [alt]="controlValue.countryCode + ' flag'"
        />

        <input
          [id]="computeId('country')"
          [(ngModel)]="controlValue.countryCode"
          [disabled]="disabled"
          readonly
        />

        <i [sch-icon]="'arrow-drop-up'" class="dropdown-icon"></i>
      </div>

      <div class="data">
        <span class="country-code mdb-text" *ngIf="controlValue.phoneCode">
          {{ controlValue.phoneCode }}
        </span>
        <input
          [id]="computeId('phone')"
          type="tel"
          [readonly]="
            controlValue?.countryCode === '' ||
            !controlValue?.countryCode ||
            readonly
          "
          [required]="required"
          [attr.aria-label]="phoneLabel"
          [placeholder]="placeholder"
          [attr.aria-required]="required"
          [maxlength]="phoneNumberLength"
          [disabled]="disabled"
          (keyup)="onPhoneNumberChanged($event)"
          [(ngModel)]="formattedPhoneNumber"
          class="phone mdb-text"
          (blur)="inputFocused = false; touched = true; onTouched()"
          (focus)="inputFocused = true"
        />
      </div>

      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="dropdownOpen"
        [cdkConnectedOverlayOffsetY]="10"
        (overlayOutsideClick)="dropdownOpen = false"
      >
        <div class="country-list" [style.width]="phoneGroupRefWidth">
          <div class="search-box">
            <i [sch-icon]="'search'" class="search-icon"></i>
            <input
              id="search"
              type="text"
              aria-label="country search"
              placeholder="Search for country"
              [(ngModel)]="searchKey"
              (keyup)="onSearch()"
            />
            <seech-shared-spinner
              [color]="'primary'"
              size="1.5rem"
              *ngIf="loading"
            >
            </seech-shared-spinner>
          </div>

          <ul
            sch-scroll-end-notifier
            [offset]="threshold"
            (scrollEnd)="onScrollToEnd()"
          >
            <li
              *ngFor="let country of paginatedItems"
              (click)="onCountryCodeSelect(country)"
              [class.selected]="
                country.countryCode === controlValue.countryCode
              "
            >
              <img
                src="https://flagcdn.com/w20/{{
                  country.countryCode.toLowerCase()
                }}.png"
                class="flag"
                [alt]="country.countryName + ' flag'"
              />
              <p class="country">
                {{ country.countryName }} ({{ country.phoneCode }})
              </p>
            </li>
          </ul>
        </div>
      </ng-template>
    </div>
  </div>

  <div *ngIf="enableExtension" class="extension">
    <ng-container *ngIf="extensionLabel; else extensionTemplate">
      <label for="extensionInput" class="mdb-text">{{ extensionLabel }}</label>
    </ng-container>

    <ng-template #extensionTemplate>
      <ng-content select="[extensionLabel]"></ng-content>
    </ng-template>

    <input
      [id]="computeId('extensionInput')"
      type="number"
      [class.focused]="dropdownOpen"
      [readonly]="
        controlValue?.countryCode === '' ||
        !controlValue?.countryCode ||
        readonly
      "
      [required]="required"
      aria-label="extension"
      [attr.aria-required]="required"
      (keyup)="onExtensionNumberChanged()"
      [maxlength]="extensionLength"
      [(ngModel)]="extensionNumber"
      [disabled]="disabled"
      class="mdb-text"
      (blur)="inputFocused = false"
      (focus)="inputFocused = true"
    />
  </div>
</div>
