import { InjectionToken } from '@angular/core';
import { PhoneValidatorInterface } from './phone-number.interface';
import { PhoneValidator } from './phone-number';

//PhoneNumberInterface will be injected in place of PhoneNumber, see demo component for example
//This is Dependency inversion principle in SOLID, this enables for easy swap of different PhoneNumber validator
//implementation during testing
//You can learn more about injection token here https://angular.io/guide/dependency-injection-in-action#supply-a-custom-provider-with-inject
export const PHONE_NUMBER_TOKEN = new InjectionToken<PhoneValidatorInterface>(
  'phone-number-validator', //phone-number-validator here is just a description and can be anything
  {
    //  providedIn: 'any'; meaning whichever component inject this class will also be the one to implicitly provide it
    //  this approach is better to providedIn:'root' as the 'root' provider will cause the instance of this class to be
    //  available on app startup and throughout the application lifecycle, where's this class will likely be used only in a component
    //  throughout the application lifecycle, which will result in an extra load for the app on app startup and extra
    //  memory consumption by the app on app startup as the user might not even use the validate phone number functionality
    //  through his/her use of the application. This approach is good for this use case.
    providedIn: 'any',
    factory: () => new PhoneValidator(),
  }
);
