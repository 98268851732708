import { Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[sch-character-limit]',
  standalone: true
})
export class CharacterLimitDirective implements OnInit {
  @Input() maxLength: number | null = null;  // Default is deactivated
  @Input() threshold = 80; // Threshold as a percentage, defaults to 80%
  @Input() embedCounter = true; // Flag to control element creation
  @Input() allowExceed = false; // Flag to allow characters exceeding the maxLength

  @Output() characterLimitReached = new EventEmitter<void>(); // Event when character limit is reached
  @Output() characterLimitExceeded = new EventEmitter<boolean>(); // Event when character limit is exceeded

  private countElement!: HTMLElement;
  private isWithinLimit = true;
  private hasReachedLimit = false; // New flag to track if the limit has been reached

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit(): void {
    if (this.maxLength && this.maxLength > 0) {
      if (this.embedCounter) {
        this.createCountElement();
      }
      this.updateCharacterCount();
    }
  }

  @HostListener('input', ['$event']) onInput(event: Event) {
    if (this.maxLength && this.maxLength > 0) {
      const input = event.target as HTMLInputElement;
      const currentLength = input.value.length;

      // Handle limit reached but not exceeded scenario
      if (currentLength === this.maxLength && !this.hasReachedLimit) {
        this.hasReachedLimit = true;
        this.characterLimitReached.emit(); // Emit event when limit is reached
      }

      // If allowExceed is false, truncate the input to the maxLength
      if (currentLength > this.maxLength && !this.allowExceed) {
        input.value = input.value.substring(0, this.maxLength);
        this.characterLimitExceeded.emit(false); // Emit false to indicate limit is exceeded but input is truncated
      }

      // If allowExceed is true, emit event when limit is exceeded
      if (this.allowExceed && currentLength > this.maxLength) {
        this.emitExceedState(false); // Emit false to indicate limit is exceeded
      } else if (currentLength <= this.maxLength) {
        this.emitExceedState(true); // Emit true to indicate user is within the limit
        this.hasReachedLimit = false; // Reset the limit reached state
      }

      // Update the character count if counter is enabled
      if (this.embedCounter) {
        this.updateCharacterCount();
        this.applyClassBasedOnCount(currentLength);
      }
    }
  }

  private emitExceedState(isWithinLimit: boolean) {
    if (this.isWithinLimit !== isWithinLimit) {
      this.isWithinLimit = isWithinLimit;
      this.characterLimitExceeded.emit(!isWithinLimit);
    }
  }

  private createCountElement() {
    this.countElement = this.renderer.createElement('div');
    this.renderer.addClass(this.countElement, 'hint');

    // Use inline styles to make it part of the flex layout
    this.renderer.setStyle(this.countElement, 'font-size', 'small');
    this.renderer.setStyle(this.countElement, 'margin-left', 'auto'); // Align to the right of the container

    // Get the parent element of the input
    const parent = this.el.nativeElement.closest('.my-3') || this.el.nativeElement.parentNode;

    // Find the label using the class 'sch-control-label'
    const label = parent.querySelector('.sch-control-label');
    if (label) {
      // If label exists, create a flex container
      const flexContainer = this.renderer.createElement('div');
      // set style
      this.renderer.setStyle(flexContainer, 'display', 'flex');
      this.renderer.setStyle(flexContainer, 'justify-content', 'space-between');
      this.renderer.setStyle(flexContainer, 'align-items', 'center');
      // remove margin from label
      this.renderer.setStyle(label, 'margin', '0');

      // Move the label into the flex container
      this.renderer.appendChild(flexContainer, label);
      // Append the count element inside the flex container
      this.renderer.appendChild(flexContainer, this.countElement);

      // Insert the flex container into the parent before the textarea
      this.renderer.insertBefore(parent, flexContainer, this.el.nativeElement);
    } else {
      // If no label is found, insert the countElement above the input element
      this.renderer.setStyle(this.countElement, 'position', 'absolute');
      this.renderer.setStyle(this.countElement, 'top', '0');
      this.renderer.setStyle(this.countElement, 'right', '0');
      this.renderer.setStyle(this.countElement, 'margin-top', '-20px');

      this.renderer.setStyle(parent, 'position', 'relative'); // Ensure the parent is positioned
      this.renderer.insertBefore(parent, this.countElement, this.el.nativeElement);
    }
  }

  private updateCharacterCount() {
    const currentLength = this.el.nativeElement.value.length;
    this.countElement.innerHTML = `${currentLength}/${this.maxLength}`;
  }

  private applyClassBasedOnCount(currentLength: number) {
    const warningThreshold = this.maxLength! * (this.threshold / 100);

    if (currentLength > this.maxLength!) {
      this.applyClass('error');
    } else if (currentLength >= warningThreshold) {
      this.applyClass('warning');
    } else {
      this.applyClass('normal');
    }
  }

  private applyClass(className: string) {
    this.renderer.removeClass(this.countElement, 'normal');
    this.renderer.removeClass(this.countElement, 'warning');
    this.renderer.removeClass(this.countElement, 'error');
    this.renderer.addClass(this.countElement, className);
  }
}
