import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { IconDirective } from '@seech/shared-ng';
import { LocationAutocompleteService } from '../../services';
import { ComboSelectComponent } from '../combo-select/combo-select.component';

// eslint-disable-next-line no-var
declare var google: any;

@Component({
  standalone: true,
  selector: 'seech-location-autocomplete',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './location-autocomplete.component.html',
  styleUrls: ['./location-autocomplete.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IconDirective,
    ComboSelectComponent,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocationAutocompleteComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LocationAutocompleteComponent),
      multi: true,
    },
  ],
})
export class LocationAutocompleteComponent implements AfterViewInit {
  @ViewChild('locationInput', { static: true })
  locationInput!: ComboSelectComponent;

  @Input() mapEnabled = false;
  @Input() autoSelect = true;
  @Input() chipsColor = 'info';
  @Input() results: any[] = [];
  @Input() value: string | string[] | any = '';
  @Input() id: string | number | null | undefined;
  @Input() placeholder = 'Enter a location';
  @Input() hideBorder = false;
  @Input() maxRowCount = 2;
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() label?: string;
  @Input() set coordinates(coords: any) {
    if (coords) {
      this.getCountryCode(coords);
    }
  }
  @Input() maxSelectionCount = 1;

  @Output() placeSelected = new EventEmitter<any>();
  @Output() countryCodeSelected = new EventEmitter<string>();
  @Output() valueChange = new EventEmitter<any>();
  @Output() selectedChange = new EventEmitter<string[] | any[]>();
  @Output() selectionLimitReached = new EventEmitter<boolean>();

  protected formattedLocation!: any;

  constructor(
    private cdr: ChangeDetectorRef,
    private locationAutocompleteService: LocationAutocompleteService
  ) {}

  ngAfterViewInit(): void {
    this.locationAutocompleteService
      .load(this.mapEnabled)
      .then(() => {
        this.initialize_autocomplete();
      })
      .catch((error) => {
        console.error('Error loading Google Maps API:', error);
      });
  }

  protected initialize_autocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(
      this.locationInput.comboInput.nativeElement
    );

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();

      if (place.geometry) {
        let address = '';

        if (place.address_components) {
          address = [
            (place.address_components[0] &&
              place.address_components[0].short_name) ||
              '',
            (place.address_components[1] &&
              place.address_components[1].short_name) ||
              '',
            (place.address_components[2] &&
              place.address_components[2].short_name) ||
              '',
            (place.address_components[3] &&
              place.address_components[3].short_name) ||
              '',
            (place.address_components[4] &&
              place.address_components[4].long_name) ||
              '',
            (place.address_components[5] &&
              place.address_components[5].long_name) ||
              '',
            (place.address_components[6] &&
              place.address_components[6].long_name) ||
              '',
          ].join(' ');
        }

        this.formattedLocation = {
          location: this.locationInput.comboInput.nativeElement.value.trim(),
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
          coords:
            place.geometry.location.lat() +
            ', ' +
            place.geometry.location.lng(),
        };

        this.value = this.locationInput.comboInput.nativeElement.value;
        this.cdr.detectChanges();
        this.placeSelected.emit(this.formattedLocation);

        if (this.autoSelect) {
          this.triggerEnterKey();
        }
      }
    });
  }

  private getCountryCode(coords: any) {
    setTimeout(()=> {
      //prefill the selected address with the location string
      this.locationInput.inputValue = coords.location;
      this.triggerEnterKey();
    }, 500)

    const geocoder = new google.maps.Geocoder();
    const latLng = new google.maps.LatLng(coords.latitude, coords.longitude);

    geocoder.geocode({ location: latLng }, (results: any[], status: string) => {
      if (status === 'OK' && results[0]) {
        const addressComponents = results[0].address_components;
        const countryComponent = addressComponents.find((component: any) =>
          component.types.includes('country')
        );

        if (countryComponent) {
          const countryCode = countryComponent.long_name;
          this.countryCodeSelected.emit(countryCode);
        }
      }
    });
  }

  // Method to trigger the enter keystroke
  protected triggerEnterKey() {
    const event = new KeyboardEvent('keyup', {
      bubbles: true,
      cancelable: true,
      key: 'Enter',
      code: 'Enter',
    });
    this.locationInput.comboInput.nativeElement.dispatchEvent(event);
  }

  onSelectionLimitReached(event: boolean) {
    this.selectionLimitReached.emit(event);
  }
}
