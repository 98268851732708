<div class="sch-autocomplete-container w-100">
  <label *ngIf="label && variant === textboxVariants.CLASSIC" 
    class="sch-input-label {{variant}}"
    for="{{ id }}"
    [attr.readonly]="readonly"
    [attr.disabled]="disabled"
  >
    {{ label }}
  </label>

  <div class="position-relative">
    <input
      sch-input
      #autocompleteInput
      (input)="onInputChanged($event)"
      type="text"
      placeholder="{{ placeHolder }}"
      [value]="value"
      class="w-50 w-100 sch-input-field {{variant}}"
      [ngClass]="{ loading: loading }"
      [id]="id"
      autocomplete="{{ autocomplete }}"
      [readonly]="readonly"
      [disabled]="disabled"
      [class.has-value]="value"
      [class.has-placeholder]="placeHolder"
      (blur)="blurr($event)"
      (change)="autocompleteChange($event)"
    />

    <label *ngIf="label && variant !== textboxVariants.CLASSIC" 
      class="sch-input-label {{variant}}"
      for="{{ id }}"
      [attr.readonly]="readonly"
      [attr.disabled]="disabled"
    >
      {{ label }}
    </label>

    <div
      class="action-info-tray"
      *ngIf="loading || canClear"
      [ngClass]="{ loading: loading }"
    >
      <seech-shared-spinner
        [color]="'primary'"
        *ngIf="loading"
        size="23px"
        [strokeWidth]="1"
      >
      </seech-shared-spinner>

      <i
        [id]="id"
        [sch-icon]="'cancel'"
        *ngIf="canClear"
        class="sch-input-icon close-icon text-primary"
        (click)="clearInput()"
      ></i>
    </div>

    <ng-content select="[action-icon]"></ng-content>
  </div>

  <div
    sch-scroll-end-notifier
    [offset]="threshold"
    (scrollEnd)="onScrollEnd()"
    *ngIf="showDropdown && results.length > 0"
    class="sch-autocomplete-dropdown"
  >
    <ng-container *ngFor="let option of results; index as i">
      <div *ngIf="!(!showSelected && value === option[labelKey])"
        class="sch-autocomplete-option"
        (click)="onOptionClicked(option)"
        [ngClass]="{ selected: value === option[labelKey], disabled: disabled }"
        id="complete-{{ i + 1 }}"
      >
        <div class="d-flex column-gap-3">
          <div class="sch-option-key">
            {{ option[nameKey] }}
          </div>
          <div class="sch-option-value" *ngIf="option[valueKey]">
            {{ option[valueKey] }}
          </div>
        </div>
      </div>
    </ng-container>
    <div class="fixed-bottom-bar"></div>

    <!-- <seech-shared-progress-bar
      *ngIf="loading"
      [progress]="30"
      [isIndeterminate]="true"
      [color]="progressBarColor"
    ></seech-shared-progress-bar> -->
  </div>
</div>
