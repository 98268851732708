import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, Input, NO_ERRORS_SCHEMA } from '@angular/core';
import { MdbAccordionModule } from 'mdb-angular-ui-kit/accordion';

@Component({
  selector: 'seech-accordion',
  standalone: true,
  imports: [
    MdbAccordionModule,
    CommonModule,   
  ],
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
  schemas:[
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class AccordionComponent {
  @Input() borderless = false;
  @Input() compact = false;
  @Input() multiple = false;
  @Input() id = "";
}
