import {
  AbstractControl,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { PhoneValueInterface } from './phone.component';
import { PHONE_NUMBER_TOKEN, PhoneValidatorInterface } from '@seech/shared-ng';
import { Inject } from '@angular/core';

//I moved Validator implementation to a different class to reduce the code in the PhoneComponent class
// and the responsibility of the PhoneComponent class
export class PhoneNgValidator implements Validator {
  constructor(
    @Inject(PHONE_NUMBER_TOKEN)
    private mPhoneValidator: PhoneValidatorInterface
  ) {}
  validate(
    control: AbstractControl<PhoneValueInterface, any>
  ): ValidationErrors | null {
    const valueIsRequired = control.hasValidator(Validators.required);
    const requiredError = { required: true };

    /**
     * If phone number and country code was not entered by the user and the form value is required
     * because a required validator is set for the phone input's FormControl
     */
    if (
      (!control.value ||
        !control?.value.phoneNumber ||
        !control?.value.countryCode) &&
      valueIsRequired
    ) {
      return requiredError;
    }

    /**
     * Check for phone number validation if both phone number and country code exist(Meaning the user typed a phone number)
     * irrespective of if phone number input value from the user is required or not
     */
    if (control?.value && control?.value.countryCode && control?.value.phoneNumber) {
      const phoneNumberIsNotValid = !this.mPhoneValidator.isValidPhone(
        control.value.phoneNumber,
        control.value.countryCode
      );
      if (phoneNumberIsNotValid) {
        return {
          invalidPhone: true,
        };
      }
    }

    return null;
  }
}
