import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import { StepComponent } from './step.component';

@Component({
  selector: 'seech-stepper',
  standalone: true,
  imports: [CommonModule, StepComponent],
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements AfterContentInit {
  @ContentChildren(StepComponent) steps!: QueryList<StepComponent>;

  @Input() stepperBackground = '#A6ACAF ';
  @Input() activeColor = '#007bff';
  @Input() id: string | number | null | undefined;
  @Input() markAsCompleted = false;
  @Input() showTopSection = true;
  
  @Output() stepChanged: EventEmitter<StepChangedEvent> =
    new EventEmitter<StepChangedEvent>();

  ngAfterContentInit() {
    this.selectStep(0); // Select the first step by default
  }

  selectStep(index: number) {
    this.steps.forEach((step, i) => (step.active = i === index));

    const activeStep: StepChangedEvent = {
      index: index,
      step: this.steps.toArray()[index],
    };
    this.stepChanged.emit(activeStep);
  }

  computeId(prefix: string): string | null {
    return '' + this.id ? `${prefix}-${this.id}` : null;
  }
}

export interface StepChangedEvent {
  index: number;
  step: StepComponent;
}
