<div
  class="sch-toast toast" [style.height]="height"
  [class]="classes"
  [ngClass]="{
    'toast-success': type === 'success',
    'toast-danger': type === 'error',
    'toast-info': type === 'info',
    'toast-warning': type === 'warning',
    'none-banners': !notificationRef?.config?.position?.includes('center') || width === '',
    'with-banners': notificationRef?.config?.position?.includes('center') || width !== ''
  }"
  id="{{id}}"
  role="alert"
  aria-live="assertive"
  aria-atomic="true"
>
  <div class="toast-body">
    <div class="sch-toast-container">
      <div class="d-flex align-items-center">
        <i *ngIf="titleIcon" [sch-icon]="titleIcon" class="desc-icon"></i>
  
        <div class="d-flex flex-column">
          <div class="title">{{ title }}</div>
          <div class="sch-toast-ch">{{ message }}</div>
        </div>
      </div>

      <div (click)="close()" role="button" class="sch-toast-ch-cancel">
        <i [sch-icon]="'cancel'" class="cancel-icon"></i>
      </div>
    </div>
  </div>

  <div
    [ngStyle]="{ width: progress + '%' }"
    [ngClass]="{
      'bg-success': type === 'success',
      'bg-danger': type === 'error',
      'bg-info': type === 'info',
      'bg-warning': type === 'warning'
    }"
    class="progress-line"
  ></div>
</div>
