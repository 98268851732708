<div 
id="{{id}}"
class="sch-accordion">
  <mdb-accordion
    [borderless]="borderless"
    [flush]="compact"
    [multiple]="multiple"
  >
    <ng-content></ng-content>
  </mdb-accordion>
</div>
