import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  COLOR,
  ProgressBarStyle,
  ProgressBarComponent as SharedProgressBarComponent,
} from '@seech/shared-ng';

@Component({
  selector: 'seech-progress-bar',
  standalone: true,
  imports: [CommonModule, SharedProgressBarComponent],
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ProgressBarComponent implements OnChanges {
  @Input() color = COLOR.PRIMARY;
  @Input() progress = 0;
  @Input() min = 0;
  @Input() max = 100;
  @Input() isIndeterminate = false;
  @Input() height = '4px';
  @Input() borderRadius = '0px';
  @Input() shape: ProgressBarStyle = 'horizontal'; 

  classes = this.getProgressBackground();
  indeterminateClasses = this.getIndeterminateProgressBackground();

  public get width(): string {
    return `width: ${this.progress}%`;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    this.classes = this.getProgressBackground();
    this.indeterminateClasses = this.getIndeterminateProgressBackground();
  }
  getProgressBackground() {
    return `bg-${this.color}`;
  }

  getIndeterminateProgressBackground() {
    return `indeterminate-${this.color}`;
  }
}
