import { Injectable } from '@angular/core';
import { ControlsConfigService } from '../injection/controls-config.service';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private scriptLoadingPromise: Promise<void> | null = null;
  // private scriptId = 'google-maps-script'; // ID to track the script element

  constructor(private controlsConfigService: ControlsConfigService) {}

  load(mapEnabled: boolean): Promise<void> {
    // If a loading process is already in progress, return the existing promise
    if (this.scriptLoadingPromise) {
      return this.scriptLoadingPromise;
    }

    // Remove any existing script with the same ID
    // const existingScript = document.getElementById(this.scriptId);
    // if (existingScript) {
    //   existingScript.remove(); // Remove the old script if it exists
    // }

    this.scriptLoadingPromise = new Promise<void>((resolve, reject) => {
      const apiKey = this.controlsConfigService.getConfig().googleAPIKey;

      if (!apiKey) {
        console.error('Google API Key not injected into controls-ng');
        reject('Google API Key not injected into controls-ng');
        return;
      }

      const script = document.createElement('script');
      // script.id = this.scriptId; // Assign a unique ID to track the script
      script.type = 'text/javascript';
      script.async = true;
      script.defer = true;

      let libraries = 'places';
      if (mapEnabled) {
        libraries += ',drawing';
      }

      const scriptUrl = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=${libraries}`;

      // Use Trusted Types policy for setting the script URL
      try {
        if (window['trustedTypes'] && window['defaultTrustedTypesPolicy']) {
          script.src = window['defaultTrustedTypesPolicy'].createScriptURL(
            scriptUrl
          ) as unknown as string;
        } else {
          script.src = scriptUrl;
        }
      } catch (e) {
        console.error('Error using Trusted Types policy:', e);
        script.src = scriptUrl;
      }

      script.onload = () => {
        resolve();
      };

      script.onerror = (error: any) => {
        this.scriptLoadingPromise = null; // Reset the loading promise on failure
        reject(error);
      };

      document.head.appendChild(script);
    });
    return this.scriptLoadingPromise;
  }
}
