import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgFor, NgIf } from '@angular/common';
import { IconDirective } from '@seech/shared-ng';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MDCList } from '@material/list';
import { Navigation } from '../../models';
@Component({
  selector: 'seech-side-nav-items',
  standalone: true,
  imports: [
    IconDirective,
    NgIf,
    NgFor,
    MdbRippleModule,
    RouterLink,
    RouterLinkActive,
  ],
  templateUrl: './side-nav-items.component.html',
  styleUrls: ['./side-nav-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavItemsComponent implements OnDestroy, OnInit {
  @Input({ required: true }) navigationItems: Navigation[] = [];
  @ViewChild('navList', { static: true }) navList!: ElementRef;
  @Input() iconClass = '';
  @Input() labelClass = '';
  @Input() routeActiveClass = '';
  @Input() showIndictor = true;

  private mdcList?: MDCList;
  @Output() selectionChange = new EventEmitter<void>();

  getNavActiveClass() {
    return this.showIndictor
      ? `${this.routeActiveClass} seech-nav-item-indicator`
      : this.routeActiveClass;
  }

  ngOnInit(): void {
    this.mdcList = new MDCList(this.navList.nativeElement);
    this.mdcList.singleSelection = true;
    this.mdcList.listen('MDCList:selectionChange', () => {
      this.selectionChange.emit();
    });
  }

  ngOnDestroy(): void {
    this.mdcList?.destroy();
  }
}
