import { CUSTOM_ELEMENTS_SCHEMA, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import { IconDirective, SharedScrollEndNotifierDirective } from '@seech/shared-ng';
import { InputDirective } from '../../directives';
import { CommonModule } from '@angular/common';

export interface Currency {
  currencyCode: string;
  currencyName: string;
  countryCode: string;
  currencySymbol?: string;
}

@Component({
  selector: 'seech-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss'],
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CurrencyComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CurrencyComponent),
      multi: true,
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    InputDirective,
    IconDirective,
    SharedScrollEndNotifierDirective,
    FormsModule,
    CommonModule,

  ],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class CurrencyComponent implements ControlValueAccessor, Validator, OnChanges {
  @Input() selectedValue: any = {}
  @Input() options: Currency[] = [];
  @Input() label?: string;
  @Input() id = '';
  @Input() autocomplete: 'on' | 'off' = 'off';
  @Input() threshold = 100;
  @Input() variation: 'single-line' | 'multi-line' = 'single-line';
  @Input() disabled = false;
  @Input() readonly = false;


  @Output() closed = new EventEmitter<boolean>();
  @Output() opened = new EventEmitter<boolean>();
  @Output() selected:any = new EventEmitter<Currency>();
  @Output() scrollEnd = new EventEmitter<void>();
  @Output() searchValueChange = new EventEmitter<Currency>();

  showDropdown = false;

  @ViewChild('dropdownMenu') dropdownMenu!: ElementRef;

  ngOnChanges(changes: SimpleChanges): void {
    if ('selectedValue' in changes) {
      this.selectOption(this.selectedValue, 0);
    }

    if('options' in changes){
      this.options = changes['options'].currentValue;
    }
  }

  constructor(
    private eRef: ElementRef,
    private cdr: ChangeDetectorRef) { 
    }

  handleViewDropDown(show: boolean) {
    if(this.readonly || this.disabled) return;
    this.showDropdown =  show;
    show === true ? this.opened.emit(true) : this.opened.emit(false);
    this.cdr.detectChanges();
  }

  selectOption(option: any, index: number) {
    this.selectedValue = option;
    this.onChange(option);
    this.onTouched();
    this.selected.emit(option);
    this.handleViewDropDown(false);
    this.cdr.detectChanges();
  }

  onInput(event: any) {
    this.showDropdown = true;
    this.searchValueChange.emit(event);
    this.onChange(event);
    this.onTouched();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  private onChange = (value: any) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouched = () => {};

  writeValue(value: any): void {
    if(value){
      this.selectedValue = value;
    }
    this.cdr.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    const valueIsRequired = control.hasValidator(Validators.required);
    const requiredError = { required: true };
    if (!value && valueIsRequired) {
      return requiredError;
    }
    return null;
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    const clickedInside = this.eRef.nativeElement.contains(targetElement);
    if (!clickedInside && this.showDropdown) {
      this.handleViewDropDown(false);
    }
  }

  onScrollEnd(){
    this.scrollEnd.emit();
  }

}
