import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Injector, Input, OnChanges, Output, ViewEncapsulation } from '@angular/core';
import { AlertService } from '../../services/alert.service';
import { IconDirective } from '@seech/shared-ng';
@Component({
  selector: 'seech-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IconDirective
  ],
  encapsulation: ViewEncapsulation.None,
})
export class AlertComponent implements OnChanges{
  @Input() color = '';
  @Input({ required: true }) title = '';
  @Input() message = '';
  @Input({ required: true }) type = '';
  @Input() config: any = {};
  @Input() closed = false;
  @Input({ required: true }) id: string | number = '';
  @Input() width="";
  @Input() height; //pass in the unit alongside height being specified eg '4rem/4px'
  @Input() titleIcon: any = '';
  @Output() closedChange: EventEmitter<any> = new EventEmitter<any>();

  classes = this.getAlertBackground();
  alertService: AlertService| null| any = this.getAlertService();
  refId = '';
  notificationRef: any;

  constructor(
    private injector: Injector,
  ) {

    this.alertService.getAlertRef().subscribe({
      next: (data:any) => {
        if (data) {
          this.notificationRef = data.component;
        }
      },
    })
  }

  ngOnChanges(): void {
    this.classes = this.getAlertBackground();
  }
  getAlertBackground() {
    return `bg-${this.color}`;
  }

  getAlertService(): AlertService | null {
    try {
      return this.injector.get<AlertService>(AlertService);
    } catch (e) {
      console.error('ToastService not found:', e);
      return null;
    }
  }

  onAlertClose() {
    this.closed = true;
    this.closedChange.emit(this.closed);
  }

  close() {
    this.alertService.close(this.refId);
  }
}
