<div
  class="combo-button-wrapper"
  [ngClass]="{
    'combo-fill': fill === FILL.SOLID,
    'combo-outline': fill === FILL.OUTLINE
  }"
>
  <button
    type="button"
    (click)="onClick($event)"
    [ngClass]="classes"
    [disabled]="disabled || fill === FILL.OUTLINE"
    [id]="id"
  >
    <i
      class="me-2"
      [ngClass]="{ 'ms-n4': shouldDisplayDropdown() }"
      [sch-icon]="icon"
    ></i>
    {{ label }}
  </button>

  <span
    *ngIf="shouldDisplayDropdown()"
    mdbDropdown
    #dropdown
    class="dropdown drp-btn"
    [ngClass]="{ 'drp-btn-sm': size === 'sm', 'drp-btn-lg': size === 'lg' }"
    id="combo-button-dropdown"
  >
    <i
      aria-expanded="false"
      id="dropdownMenuButton"
      type="button"
      mdbDropdownToggle
      class="dropdown-toggle combo-dropdown-toggle"
      [ngClass]="{
        'text-white': fill === FILL.SOLID,
        'in-action-dropdown text-blue': fill === FILL.OUTLINE
      }"
    ></i>

    <ul
      [id]="computeId('dropdown-menu')"
      mdbDropdownMenu
      class="dropdown-menu"
      [attr.aria-labelledby]="id"
    >
      <li *ngFor="let item of dropdownItems">
        <a
          sch-dropdown-item
          class="dropdown-item"
          href="javascript:"
          (click)="onListItemClicked($event, item.value)"
          id="{{ item.id }}"
        >
          {{ item.label }}
        </a>
      </li>
    </ul>
  </span>
</div>
