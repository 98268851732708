<!-- <div mdbDropdown class="dropdown btn-group">
  <button
    *ngIf="split"
    [class.disabled]="disabled"
    [fill]="fill"
    type="button"
    [id]="id"
    sch-button
    [size]="size"
    [color]="color"
  >
    <i *ngIf="icon" [sch-icon]="icon"></i> &nbsp;{{ label }} &nbsp;
  </button>

  <div *ngIf="split" class="vr" [class]="dividerColorClass"></div>

  <button
    class="dropdown-toggle"
    [fill]="fill"
    type="button"
    [class]="classes"
    [id]="id"
    aria-expanded="false"
    mdbDropdownToggle
    sch-button
    [size]="size"
    [color]="color"
    [class.disabled]="disabled"
  >
    <span *ngIf="!split">
      <i *ngIf="icon" [sch-icon]="icon"></i> &nbsp;{{ label }} &nbsp;</span
    >
  </button>

  <ul
    [id]="computeId('dropdown-menu')"
    mdbDropdownMenu
    class="dropdown-menu"
    [class]="menuAlignmentClasses"
    [attr.aria-labelledby]="id"
  >
    <ng-content> </ng-content>
  </ul>
</div> -->

<seech-shared-dropdown [disabled]="disabled" [split]="split" [size]="size" [direction]="direction" [shape]="shape"
  [alignment]="alignment" [color]="color" [fill]="fill" [label]="label" [icon]="icon" [id]="id">
  <ng-content> </ng-content>
</seech-shared-dropdown>