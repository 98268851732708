import { NgModule } from '@angular/core';
import {
  ChipComponent,
} from '@seech/shared-ng';
import {
  AlertComponent,
  PlaceholderComponent,
  SpinnerComponent,
  TabsComponent,
  TabComponent,
  ProgressBarComponent,
  AccordionComponent,
  AccordionItemComponent,
  ChipsComponent,
  StepperComponent,
  StepComponent,
  BadgeComponent,
  ConfirmationDialogComponent,
} from './components';
import { DialogModule, ModalModule, NotificationModule, TooltipModule } from './modules';
import { CommonModule } from '@angular/common';
import { BadgeDirective, ScrollEndNotifierDirective } from './directives';
import { ToastComponent } from './components/toast/toast.component';

export const COMPONENTS = [
  AlertComponent,
  PlaceholderComponent,
  SpinnerComponent,
  TabsComponent,
  TabComponent,
  AccordionComponent,
  AccordionItemComponent,
  ProgressBarComponent,
  TabsComponent,
  DialogModule,
  ModalModule,
  NotificationModule,
  ChipComponent,
  ChipsComponent,
  CommonModule,
  TooltipModule,
  StepperComponent,
  StepComponent,
  BadgeComponent,
  BadgeDirective,
  ConfirmationDialogComponent,
  ScrollEndNotifierDirective,
  ToastComponent
];

@NgModule({
  imports: COMPONENTS,
  exports: COMPONENTS,
})
export class UxNgModule {}
