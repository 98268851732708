import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { MdbDropdownModule } from 'mdb-angular-ui-kit/dropdown';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ControlsNgModule, DropdownComponent, DropdownDirective } from '@seech/controls-ng';
import { LayoutNgModule } from '@seech/layout-ng';
import { FormsModule } from '@angular/forms';
import { environment } from '../environments/environment';
import { UxNgModule } from '@seech/ux-ng';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule,
    MdbRippleModule,
    MdbDropdownModule,
    DropdownComponent,
    DropdownDirective,
    LayoutNgModule,
    FormsModule,
    UxNgModule,

    ControlsNgModule.forRoot({
      googleAPIKey: environment.GOOGLE_API_KEY,
      buttonShape: 'normal',
      dropDownShape: 'normal',
      comboButtonShape: 'rounded',
      radioButtonShape: 'normal',
      checkboxShape: 'circle',
      switchVariant: 'modern',
      textboxVariant: 'hybrid',
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
