import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Color } from '@seech/shared-ng';

/*eslint-disable @angular-eslint/component-selector */
@Component({
  selector: '[sch-badge]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent implements OnChanges {
  @Input() badgeColor?: Color = 'danger';
  @Input('sch-badge') showBadge = true;

  classes = this.getHostElementClasses();

  private getHostElementClasses(): string {
    return this.badgeColor ? `bg-${this.badgeColor}` : '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.classes = this.getHostElementClasses();
  }
}
