<div [attr.align]="null" class="seech-layout-inner-container d-flex h-100 flex-column justify-content-between"
  [class]="sideNavClasses" #content>

  <ng-content></ng-content>

  <div class="seech-nav-footer">
    <ng-content select="[sidenav-footer]"></ng-content>
  </div>
</div>

