import { Directive, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[sch-character-limit]',
  standalone: true
})
export class CharacterLimitDirective implements OnInit, AfterViewInit {
  @Input() maxLength: number | null = null;
  @Input() threshold = 80;
  @Input() embedCounter = true;
  @Input() allowExceed = false;

  @Output() characterLimitReached = new EventEmitter<void>();
  @Output() characterLimitExceeded = new EventEmitter<boolean>();

  private countElement!: HTMLElement;
  private isWithinLimit = true;
  private hasReachedLimit = false;
  private targetInput: HTMLInputElement | HTMLTextAreaElement | null = null;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit(): void {
    if (this.maxLength && this.maxLength > 0 && this.embedCounter) {
      this.createCountElement();
    }
  }

  ngAfterViewInit(): void {
    this.findTargetInput();
    if (this.targetInput) {
      // Handle input event to update on any change
      this.renderer.listen(this.targetInput, 'input', (event: Event) => this.onInput(event));

      // Check for prefilled values and update the counter and validations immediately
      if (this.targetInput.value.length > 0) {
        this.onInput(new Event('input')); // Trigger an initial input event to process prefilled value
      } else {
        this.updateCharacterCount(); // Still update the character count initially if empty
      }
    }

    // Attempt to find the label element in the view
    this.attachCounterToLabel();
  }

  private findTargetInput(): void {
    const hostElement = this.el.nativeElement;
    if (hostElement.tagName === 'INPUT' || hostElement.tagName === 'TEXTAREA') {
      this.targetInput = hostElement;
    } else {
      this.targetInput = hostElement.querySelector('input, textarea');
    }

    if (!this.targetInput) {
      // console.warn('CharacterLimitDirective: No input or textarea found within the host element.');
    }
  }

  private attachCounterToLabel(): void {
    const parent = this.el.nativeElement.parentNode;
    const label = this.findLabelElement(parent);

    if (label) {
      this.attachCounter(label);
    } else {
      console.warn('CharacterLimitDirective: No label found for the counter.');
    }
  }

  private findLabelElement(element: any): HTMLElement | null {
    const label = element.querySelector('label, sch-input-label');
    if (label) {
      return label;
    }

    let parentElement = element.parentNode;
    while (parentElement) {
      const foundLabel = parentElement.querySelector('label, sch-input-label');
      if (foundLabel) {
        return foundLabel;
      }
      parentElement = parentElement.parentNode;
    }

    return null;
  }

  private attachCounter(label: HTMLElement): void {
    const flexContainer = this.renderer.createElement('div');
    this.renderer.setStyle(flexContainer, 'display', 'flex');
    this.renderer.setStyle(flexContainer, 'justify-content', 'space-between');
    this.renderer.setStyle(flexContainer, 'align-items', 'center');
    this.renderer.setStyle(flexContainer, 'width', '100%');

    this.renderer.setStyle(label, 'margin', '0');
    this.renderer.appendChild(flexContainer, label);
    this.renderer.appendChild(flexContainer, this.countElement);

    const parent = this.el.nativeElement.parentNode;
    this.renderer.insertBefore(parent, flexContainer, this.el.nativeElement);
  }

  onInput(event: Event): void {
    if (this.maxLength && this.maxLength > 0 && this.targetInput) {
      let currentLength = this.targetInput.value.length;

      if (!this.allowExceed && currentLength > this.maxLength) {
        this.targetInput.value = this.targetInput.value.substring(0, this.maxLength);
        currentLength = this.maxLength;

        const nativeInput = this.targetInput;
        const inputEvent = new Event('input', { bubbles: true });
        nativeInput.dispatchEvent(inputEvent);
      }

      // Check if the limit has been reached
      if (currentLength === this.maxLength && !this.hasReachedLimit) {
        this.hasReachedLimit = true;
        this.characterLimitReached.emit();
      }

      // Handle limit exceeded only if allowExceed is true
      if (this.allowExceed && currentLength > this.maxLength) {
        this.emitExceedState(false);
      } else {
        this.emitExceedState(true);
        this.hasReachedLimit = false;
      }

      // Update the character count if counter is enabled
      if (this.embedCounter) {
        this.updateCharacterCount();
        this.applyClassBasedOnCount(currentLength);
      }
    }
  }

  private emitExceedState(isWithinLimit: boolean): void {
    if (this.isWithinLimit !== isWithinLimit) {
      this.isWithinLimit = isWithinLimit;
      this.characterLimitExceeded.emit(!isWithinLimit);
    }
  }

  private createCountElement(): void {
    this.countElement = this.renderer.createElement('div');
    this.renderer.addClass(this.countElement, 'hint');
    this.renderer.setStyle(this.countElement, 'font-size', 'small');
    this.renderer.setStyle(this.countElement, 'margin-left', 'auto');
  }

  private updateCharacterCount(): void {
    if (!this.targetInput || !this.maxLength) {
      return;
    }
    const currentLength = this.targetInput.value.length;

    if (currentLength <= this.maxLength) {
      this.countElement.innerHTML = `${currentLength}/${this.maxLength} characters.`;
    } else {
      const overCount = currentLength - this.maxLength;
      this.countElement.innerHTML = `Over by ${overCount} characters.`;
    }
  }

  private applyClassBasedOnCount(currentLength: number): void {
    const warningThreshold = this.maxLength! * (this.threshold / 100);

    if (currentLength > this.maxLength!) {
      this.applyClass('text-danger');
      this.renderer.removeClass(this.countElement, 'text-warning');
      this.renderer.removeClass(this.countElement, 'normal');
    } else if (currentLength >= warningThreshold) {
      this.applyClass('text-warning');
      this.renderer.removeClass(this.countElement, 'text-danger');
      this.renderer.removeClass(this.countElement, 'normal');
    } else {
      this.applyClass('normal');
      this.renderer.removeClass(this.countElement, 'text-warning');
      this.renderer.removeClass(this.countElement, 'text-danger');
    }
  }

  private applyClass(className: string): void {
    this.renderer.removeClass(this.countElement, 'normal');
    this.renderer.removeClass(this.countElement, 'text-warning');
    this.renderer.removeClass(this.countElement, 'text-danger');
    this.renderer.addClass(this.countElement, className);
  }
}
