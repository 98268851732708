import { Injectable } from '@angular/core';
import { ToastComponent } from '../components/toast/toast.component';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})

export class ToastService {


  constructor(private notificationService:NotificationService) { }

  info(message: string, config?: config): void {
    this.notificationService.info(this.buildToast(message, config));
  }

  success(message: string, config?: config): void {
   this.notificationService.success(this.buildToast(message, config));
  }

  error(message: string, config?: config): void {
    this.notificationService.error(this.buildToast(message, config));
  }

  warning(message: string, config?: config): void {
    this.notificationService.warning(this.buildToast(message, config));
  }

  // get notification ref

  getToastRef(): BehaviorSubject<any> {
    return this.notificationService.getNotifiedComponentRef();
  }

  close(id:string){
    const stackedComponents = this.notificationService.getStackedComponents();
    const componentToBeClosed = stackedComponents.find((component:any) => {
        return component?.component?.uId === id;
    });
    componentToBeClosed?.close();
    // this.notificationService.getNotificationRef()?.close();
  }

  private buildToast(
    message: string,
    config: config | any,
  ) {
    return {
      componentToBeNotified: ToastComponent,
      message: message,
      config: config
    }
  }
}

interface config {
  position: any;
  width: any;
  delay: number;
  autohide: boolean;
  stacking: boolean;
  offset: number;
  animation: boolean;
  data: {
    text: string;
  };
}
