<div class="sch-tabs" [ngClass]="{ 'fill': fill || justified, }">
  <div
    *ngFor="let tab of tabs; let i = index"
    class="sch-tabs-item"
    [ngClass]="{ active: tab.active, justify: justified, 
    'custom-background': tab.active && activeTabStyle.backgroundColor,
    'custom-border': tab.active && activeTabStyle.borderBottomColor
    }"
    [style.--active-tab-background-color]="tab.active ? activeTabStyle.backgroundColor : ''"
    [style.--active-tab-border-color]="tab.active ? activeTabStyle.borderBottomColor : ''"
  >
    <i *ngIf="tabIcon" [sch-icon]="tabIcon" class="tab-icon"
    [ngClass]="{
      'custom-color': tab.active && activeTabStyle.titleColor
    }"
    [style.--active-tab-title-color]="tab.active ? activeTabStyle.titleColor : ''">
    </i>
    
    <p
      [ngClass]="{ active: tab.active,
      'custom-color': tab.active && activeTabStyle.titleColor }"
      class="sch-tabs-title m-0"
      (click)="selectTab(i)"
      [style.--active-tab-title-color]="tab.active ? activeTabStyle.titleColor : ''"
      [innerHTML]="tab.title"
    ></p>
  </div>
</div>
<ng-content></ng-content>
