import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import {ButtonDirective,  IconDirective} from '@seech/shared-ng'
import { DialogService } from '../../services';

@Component({
  selector: 'seech-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ButtonDirective,
    IconDirective
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent  {
  // title should accept a string or a template
  @Input() title: string | HTMLElement = '';
  @Input() content: string | HTMLElement = '';
  @Input() buttons: string | HTMLElement = '';
  @Input() actionBtnText = '';
  @Input() cancelBtnText = '';
  @Input() actionBtnClasses = '';
  @Input() cancelBtnClasses = '';
  @Input() id = '';

  @Output() closeDialog: EventEmitter<any> = new EventEmitter();

  constructor(
    private injector: Injector,
  ) {}

dialogService:DialogService| null| any = this.getDialogService();

  getDialogService(): DialogService | null {
    try {
      return this.injector.get<DialogService>(DialogService);
    } catch (e) {
      console.error('DialogService not found:', e);
      return null;
    }
  }



onAction() {
    this.dialogService.close();
}

  onCancel() {
    this.dialogService.close();
  }
}
