<div class="sch-input">
  <label *ngIf="label && variant === textboxVariants.CLASSIC" 
    class="sch-input-label {{variant}}" 
    for="{{ id }}" 
    [attr.readonly]="readonly" 
    [attr.disabled]="disabled">
    {{ label }}
  </label>

  <div class="position-relative">
    <input
      [id]="id"
      [type]="type"
      (isValid)="handleValidation($event)"
      [(ngModel)]="value"
      (input)="onInput()"
      [autocomplete]="autocomplete"
      class="sch-input-field {{variant}}"
      [class.has-value]="value"
      [class.has-placeholder]="placeholder"
      [placeholder]="placeholder"
      [readonly]="readonly"
      [disabled]="disabled"
    />

    <label *ngIf="label && variant !== textboxVariants.CLASSIC" 
      class="sch-input-label {{variant}}" 
      for="{{ id }}" 
      [attr.readonly]="readonly" 
      [attr.disabled]="disabled">
      {{ label }}
    </label>

    <ng-container *ngIf="hasIcon">
      <i 
        (click)="(readonly || disabled) ? null : actionIconClick.emit()"
        [sch-icon]="icon!"
        class="sch-input-icon"
      ></i>
    </ng-container>
  </div>
</div>
