import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedTooltipComponent } from '@seech/shared-ng';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sch-tooltip',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tooltip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent extends SharedTooltipComponent {
  override colorClass = '';
}
