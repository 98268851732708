import { DOCUMENT } from '@angular/common';
import {
  Inject,
  Injectable,
  OnInit,
  Renderer2,
  RendererFactory2,
} from '@angular/core';

export interface Theme {
  class: string;
  label: string;
}
@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  themes: Theme[] = [
    { class: 'light-blue', label: 'Light Blue' },
    { class: 'dark-blue', label: 'Dark Blue' },
    { class: 'light-green', label: 'Light Green' },
    { class: 'dark-green', label: 'Dark Green' },
    { class: 'light-purple', label: 'Light Purple' },
    { class: 'dark-purple', label: 'Dark Purple' },
  ];
  private renderer: Renderer2;

  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.setTheme(this.themes[0].class);
  }

  setTheme(theme: string) {
    this.renderer.setAttribute(this.document.body, 'data-theme', theme);
  }
}
