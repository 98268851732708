<div class="position-relative">
  <seech-standard-layout [hasBackdrop]="displayIsMobile" style="position: relative;">
    <div header
      class="ps-3 pe-1 px-lg-5 fixed-top top-toolbar justify-content-between d-flex justify-items-center align-items-center bg-primary shadow">

      <div class="d-flex gap-4 gap-md-5">

        <div class="d-lg-none" id="hamburger-icon">
          <svg (click)="drawer.toggle()" class="toggle" width="24" viewBox="0 0 287.54 204.08">
            <path
              d="M143.68,36.01c-41.67,0-83.34,.03-125.01-.02-12.96-.02-21.4-11.17-17.85-23.37C2.85,5.64,9.06,.64,16.45,.06c.62-.05,1.25-.05,1.87-.05,83.59,0,167.18-.02,250.78,.01,10.31,0,17.9,7.07,18.41,16.87,.56,10.74-7.16,19.01-18.06,19.1-13.97,.1-27.95,.03-41.92,.03-27.95,0-55.89,0-83.84,0Z" />
            <path
              d="M143.4,120.05c-41.55,0-83.09,.03-124.64-.02-13.01-.01-21.45-11.05-17.96-23.28,2.04-7.16,8.37-12.2,15.93-12.68,.87-.06,1.75-.04,2.62-.04,82.97,0,165.94,0,248.9,0,10.99,0,18.67,6.74,19.24,16.79,.6,10.57-7.01,19.01-17.59,19.17-10.35,.16-20.71,.04-31.07,.04-31.81,0-63.63,0-95.44,0Z" />
            <path
              d="M143.95,168.06c41.67,0,83.34-.03,125.01,.02,12.82,.01,21.17,10.91,17.85,23.05-1.98,7.24-8.17,12.31-15.75,12.89-.87,.07-1.75,.06-2.62,.06-83.09,0-166.19,0-249.28,0-10.62,0-18.18-6.48-19.06-16.2-.99-10.96,6.91-19.72,18.08-19.78,18.96-.09,37.93-.03,56.89-.03,22.96,0,45.91,0,68.87,0Z" />
          </svg>
        </div>

        <div class="sch-logo">
          <a [routerLink]="['./']" id="seech-logo">
            <img width="25" [src]="logoSrc" alt="seech logo" />
          </a>
        </div>
      </div>

      <div class="title h-100 d-flex justify-content-start align-items-center text-white">
        <p class="d-none d-sm-block pt-4">Foundation UI Components Demo</p>
        <p class="d-sm-none text-center pt-4">Demo</p>
      </div>

      <div class="d-flex flex-row gap-3">
        <!-- <seech-dropdown [id]="'theme-button'" [label]="deviceDisplayIsSmall()?'':'Theme'" [fill]="'outline'" -->
        <!-- <seech-dropdown [id]="'theme-button'" [icon]="'colors'" class="theme-toggle" [color]="'white'">
          <li *ngFor="let theme of themes">
            <button class="bg-white" (click)="onThemeChange(theme.class)" id="{{theme.class}}-theme" sch-dropdown-item>
              {{theme.label}}
            </button>
          </li>
        </seech-dropdown> -->

        <div mdbDropdown class="dropdown theme-wrapper">
          <a class="btn d-flex justify-content-center align-items-center gap-1 border btn-tertiary dropdown-toggle"
            type="button" id="theme-button" aria-expanded="false" mdbDropdownToggle>
            <img class="theme" src="assets/images/theme-icon.png">
          </a>
          <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-start theme-menu" aria-labelledby="dropdownMenuButton">
            <li *ngFor="let theme of themes">
              <a class="dropdown-item" target="_blank" (click)="onThemeChange(theme.class)" id="{{theme.class}}-theme">
                {{theme.label}}
              </a>
            </li>
          </ul>
        </div>

        <div *ngIf="!deviceDisplayIsSmall()" mdbDropdown class="dropdown storybook-wrapper">
          <a class="btn d-flex justify-content-center align-items-center gap-1 border btn-tertiary dropdown-toggle"
            type="button" id="storybook-button" aria-expanded="false" mdbDropdownToggle>
            <img class="storybook" src="assets/images/storybook.png">
            <!-- <span class="storybook-label">
              Storybook
            </span> -->
          </a>
          <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-start storybook-menu"
            aria-labelledby="dropdownMenuButton">
            <li *ngFor="let dropdown of storybookDropdown">
              <a class="dropdown-item" target="_blank" id="{{dropdown.id}}"
                [href]="getStorybookUrlByName(dropdown.route)">
                {{dropdown.label}}
              </a>
            </li>
          </ul>
        </div>

        <div *ngIf="deviceDisplayIsSmall()" mdbDropdown class="dropdown storybook-wrapper">
          <a class="btn btn-link py-2 px-3 dropdown-toggle storybook-dropdown" type="button" id="storybook-image"
            aria-expanded="false" mdbDropdownToggle>
            <img class="storybook" src="assets/images/storybook.png">
          </a>
          <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-start storybook-menu"
            aria-labelledby="dropdownMenuButton">
            <li *ngFor="let dropdown of storybookDropdown">
              <a class="dropdown-item" target="_blank" id="{{dropdown.id}}"
                [href]="getStorybookUrlByName(dropdown.route)">{{dropdown.label}}</a>
            </li>
          </ul>
        </div>

        <div class="profile-image">
          <p>ON</p>
        </div>
      </div>
    </div>

    <seech-side-nav #drawer [mode]="displayIsMobile ? 'over':'side'" [sideNavColor]="'dark'"
      [sideNavBackgroundClass]="'seech-sidenav-background'" class="sideNav-section">
      <div class="sidenav-wrapper">
        <div class="mobile-sidenav-header" *ngIf="displayIsMobile">
          <div class="sch-logo">
            <a [routerLink]="['./']" id="seech-logo">
              <img width="25" [src]="sidenavLogoSrc" alt="seech logo" />
            </a>
          </div>

          <seech-side-nav-toggle sidenav-footer [isMobile]="deviceDisplayIsSmall()" (sideNavClose)="drawer.close()"
            (toggleMinimize)="drawer.toggleMinimize()" [id]="'side-nav-toggle'" class="text-primary">
          </seech-side-nav-toggle>
        </div>

        <seech-side-nav-items [routeActiveClass]="'bg-primary text-white active-link-icon'" iconClass="nav-link-icon"
          labelClass="nav-link-label" (selectionChange)="navigationSelectionChange(drawer)"
          [navigationItems]="navigationItems" class="px-1">
        </seech-side-nav-items>

        <hr class="mx-1 my-n4" />

        <seech-side-nav-items [routeActiveClass]="'bg-primary text-white active-link-icon'" iconClass="nav-link-icon"
          labelClass="nav-link-label" (click)="openSettingsDialog()" [navigationItems]="bottomNavigationItems"
          class="px-1">
        </seech-side-nav-items>
      </div>

      <seech-side-nav-toggle *ngIf="!displayIsMobile" sidenav-footer [isMobile]="deviceDisplayIsSmall()"
        (sideNavClose)="drawer.close()" (toggleMinimize)="drawer.toggleMinimize()" [id]="'side-nav-toggle'"
        class="text-primary">
      </seech-side-nav-toggle>

    </seech-side-nav>

    <seech-layout-content class="content-section">
      <router-outlet></router-outlet>

      <footer class="footer">
        <div class="container-fluid">
          <div class="row">
            <div class="col-6">
              <div class="">
                <div class="sch-logo d-flex flex-column gap-3">
                  <img width="25" [src]="logoSrc" alt="seech logo" />
                  <p class="motto">Aspire Innovate Rule ...</p>
                </div>

                <div class="copyright">
                  <p>
                    <span class="copy">&copy; </span>
                    <span class="year">{{year}} Seech. </span>
                    All rights reserved.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-6 route-list">
              <div class="d-flex flex-column gap-3">
                <a class="routes" [routerLink]="">Seech Products</a>
                <a class="routes" [routerLink]="">Seech for Startups</a>
                <a class="routes" [routerLink]="">Seech for Developer</a>
              </div>

              <div class="d-flex flex-column gap-3">
                <a class="routes" [routerLink]="">Seech Payments</a>
                <a class="routes" [routerLink]="">Mass Payments</a>
                <a class="routes" [routerLink]="">Assets</a>
              </div>

              <div class="d-flex flex-column gap-3">
                <a class="routes" [routerLink]="">Support</a>
                <a class="routes" [routerLink]="">FAQ</a>
                <a class="routes" [routerLink]="">Contact</a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </seech-layout-content>
  </seech-standard-layout>

</div>