import {
  Directive,
} from '@angular/core';
import { IconDirective as SharedIconDirective } from '@seech/shared-ng';

@Directive({
  selector: '[sch-icon]',
  standalone: true,
})
export class IconDirective extends SharedIconDirective {
}
