/* eslint-disable @typescript-eslint/no-empty-function */
import {
  AfterViewChecked,
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MdbSelectModule } from 'mdb-angular-ui-kit/select';
import { AbstractControl, FormsModule, NG_VALIDATORS, ReactiveFormsModule, ValidationErrors, Validator } from '@angular/forms';
import { MdbDropdownModule } from 'mdb-angular-ui-kit/dropdown';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputDirective } from '../../directives';
import { IconDirective, SharedScrollEndNotifierDirective } from '@seech/shared-ng';
import {  Validators,} from '@angular/forms';
@Component({
  selector: 'seech-select',
  standalone: true,
  imports: [
    CommonModule,
    MdbDropdownModule,
    MdbRippleModule,
    FormsModule,
    ReactiveFormsModule,
    MdbSelectModule,
    InputDirective,
    IconDirective,
    SharedScrollEndNotifierDirective,
  ],
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SelectComponent implements ControlValueAccessor, Validator {
  @Input() selectedValue: any;
  @Input() options: any[] = [];
  @Input() label?:string;
  @Input() id = '';
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() autocomplete: 'on' | 'off' = 'off';
  @Input() threshold = 100;
  @Input() placeholder?: string;

  @Output() selectedValueChange = new EventEmitter<any>();
  @Output() closed = new EventEmitter<boolean>();
  @Output() opened = new EventEmitter<boolean>();
  @Output() selected = new EventEmitter<any>();
  @Output() reachedEndOfList = new EventEmitter<void>();

  showDropdown = false;
  @ViewChild('dropdownMenu') dropdownMenu!: ElementRef;

  private onChange = (value: any) => { };
  private onTouched = () => { };

  constructor(
    private eRef: ElementRef, 
    private cdr: ChangeDetectorRef) { }

  selectOption(option: any) {
    this.selectedValue = option;
    this.onChange(option);
    this.onTouched();
    this.selectedValueChange.emit(option);
    this.selected.emit(option.value);
    this.cdr.detectChanges();
    this.toggleDropdown();
  }

  onInput(event: any) {
    this.showDropdown = true;
    this.selectedValue = event;
    this.selectedValueChange.emit(event);
    this.onChange(event);
    this.onTouched();
  }

  writeValue(value: any): void {
    if(value){
      this.selectedValue = value;
    }
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
    this.showDropdown === true ? this.opened.emit(true) : this.opened.emit(false);
    this.cdr.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    // return this.selectedValue ? null : { required: true };
    const value = control.value;
    const valueIsRequired = control.hasValidator(Validators.required);
    const requiredError = { required: true };
    if (!value && valueIsRequired) {
      return requiredError;
    }
    return null;
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    const clickedInside = this.eRef.nativeElement.contains(targetElement);
    if (!clickedInside && this.showDropdown) {
      this.toggleDropdown();
      this.closed.emit(true);
      this.opened.emit(false);
    }
  }



  onScrollEnd(){
    this.reachedEndOfList.emit();
  }

}