<!-- Horizontal Progress Bar -->
<div *ngIf="shape === 'horizontal' && !isIndeterminate" class="progress" [style.height]="height" [style.border-radius]="borderRadius">
  <div class="progress-bar" [class]="classes" role="progressbar" [style]="width" [attr.aria-valuenow]="progress"
    [attr.aria-valuemin]="min" [attr.aria-valuemax]="max"></div>
</div>

<div *ngIf="shape === 'horizontal' && isIndeterminate" class="indeterminate" [style.height]="height">
  <div class="indeterminate-value" [class]="indeterminateClasses"></div>
</div>

<!-- Circular Progress Bar -->
<div *ngIf="shape === 'circular'" class="circular" [style.height]="height" [style.width]="height"
  role="progressbar" [attr.aria-valuenow]="progress" [attr.aria-valuemin]="min" [attr.aria-valuemax]="max">
  <svg class="circular-progress" viewBox="0 0 100 100">
    <circle class="background-circle" cx="50" cy="50" r="45" />
    <circle class="progress-circle" cx="50" cy="50" r="45" [attr.stroke-dasharray]="2 * Math.PI * 45"
      [attr.stroke-dashoffset]="strokeDashoffset" [class]="progressStrokeClass" />
  </svg>
  <div class="percentage-text mdb-text">{{ progress }}%</div>
</div>
