import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  forwardRef,
  ChangeDetectorRef,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validator,
  AbstractControl,
  ValidationErrors,
  FormsModule,
} from '@angular/forms';
import { Currency } from '../currency/currency.component';
import {
  ConnectedPosition,
  OverlayModule,
  ScrollStrategy,
} from '@angular/cdk/overlay';
import {
  IconDirective,
  SharedScrollEndNotifierDirective,
} from '@seech/shared-ng';

@Component({
  selector: 'seech-money',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    OverlayModule,
    IconDirective,
    SharedScrollEndNotifierDirective,
  ],
  templateUrl: './money.component.html',
  styleUrls: ['./money.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MoneyComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MoneyComponent),
      multi: true,
    },
  ],
})
export class MoneyComponent
  implements ControlValueAccessor, Validator, OnChanges
{
  @Input({ required: true }) currency: Currency | null = null;
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() required = false;
  @Input() autocomplete: 'off' | 'on' = 'off';
  @Input() validateControl = true;
  @Input() placeholder = '';
  @Input() currencySearchPlaceholder = 'Search';
  @Input() fontSize = '0.8rem';
  @Input() label?: string;
  @Input() showCurrencySelector = false;
  @Input() currencies: Currency[] = [];
  @Input() threshold = 100;
  @Output() selected: EventEmitter<Currency> = new EventEmitter<Currency>();
  @Output() scrollEnd: EventEmitter<void> = new EventEmitter<void>();
  @Output() currencySearch: EventEmitter<string> = new EventEmitter<string>();
  @Output() valueChange: EventEmitter<number> = new EventEmitter<number>();
  @Input() value?: string;

  scrollStrategy!: ScrollStrategy;
  isOpen = false;
  connectedPositions: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
      offsetY: 5,
    },
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
      offsetY: -5,
    },
  ];

  showSearchResult = false;
  formattedValue = '';
  rawValue = '';
  currencySymbol?: string;
  showError?: boolean;
  errorMessage = '';

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChange = (value: any) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouched = () => {};

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.currencySymbol = this.currency?.currencySymbol;
    if (this.value) {
      this.writeValue(this.value);
    }
  }

  onInputChange(e: any): void {
    this.rawValue = e.target.value.replace(/[^0-9.]/g, ''); // Remove all non-numeric characters except decimal
    this.valueChange.emit(parseFloat(this.rawValue));
    this.onTouched();
  }
  onSearch(e: any) {
    this.currencySearch.emit(e.target.value);
  }

  onScrollEnd() {
    this.scrollEnd.emit();
  }

  onSelected(currency: Currency) {
    this.selected.emit(currency);
    this.currency = currency;
  }

  onBlur(): void {
    this.formattedValue = this.formatCurrency(this.rawValue);
    this.onChange(this.formattedValue);
    this.onTouched();
    this.validateInput();
  }

  onFocus(): void {
    this.showError = undefined;
  }

  formatCurrency(value: string): string {
    if (!value) return '';
    const parts = value.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const formattedValue = parts.join('.');
    return parseFloat(formattedValue) === 0 ? '' : formattedValue;
  }

  getValidationColorClass() {
    let borderColorClass = '';
    if (!this.disabled) {
      if (this.showError === false && this.validateControl) {
        borderColorClass = 'border-success';
      }
      if (this.showError === true && this.validateControl) {
        borderColorClass = 'border-danger';
      }
      if (this.readonly) {
        borderColorClass = 'border-primary';
      }
      if (this.disabled) {
        borderColorClass = 'border-disabled';
      }
    }
    return `${borderColorClass} ${this.getTextColorClass()} ${this.getBackgroundColor()}`;
  }

  getBackgroundColor() {
    if (this.disabled) {
      return 'bg-disabled';
    }
    return 'mdb-bg';
  }

  getTextColorClass() {
    if (this.readonly) {
      return 'text-primary';
    }

    if (this.disabled) {
      return 'color-disabled';
    }

    return 'mdb-text';
  }

  validateInput(): void {
    const numericValue = parseFloat(this.formattedValue.replace(',', ''));
    if (this.required && (!this.formattedValue || isNaN(numericValue))) {
      this.showError = true;
      this.errorMessage = 'Amount is required';
    } else if (numericValue < 0) {
      this.showError = true;
      this.errorMessage = 'Amount cannot be negative';
    } else {
      this.showError = false;
      this.errorMessage = '';
    }
  }

  writeValue(value: any): void {
    if (value) {
      this.rawValue = value;
      this.formattedValue = this.formatCurrency(value);
    }
    this.cdRef.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.required && !control.value) {
      return { required: true };
    }
    if (control.value && parseFloat(control.value) < 0) {
      return { invalidAmount: true };
    }
    return null;
  }

  htmlSymbolToUnicode(currencySymbols: string) {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = currencySymbols;
    return textArea.value;
  }
}
