<div class="sch-input">
  <label
    *ngIf="label && variant === textboxVariants.CLASSIC"
    class="sch-input-label {{ variant }}"
    for="{{ id }}"
    [attr.readonly]="readonly"
    [attr.disabled]="disabled"
  >
    {{ label }}
  </label>

  <div class="position-relative">
    <textarea
      cdkTextareaAutosize
      cdkTextareaAutosize
      [cdkAutosizeMinRows]="minRows"
      [cdkAutosizeMaxRows]="maxRows"
      [id]="id"
      (isValid)="handleValidation($event)"
      [(ngModel)]="value"
      (change)="onInput()"
      [autocomplete]="autocomplete"
      class="sch-text-area  {{ variant }}"
      [class.has-value]="value"
      [class.has-placeholder]="placeholder"
      [placeholder]="placeholder"
      [readonly]="readonly"
      [disabled]="disabled"
      [maxLength]="maxLength"
      [threshold]="threshold"
      [embedCounter]="embedCounter"
      [allowExceed]="allowExceed"
      sch-character-limit
      (characterLimitReached)="onCharacterLimitReached()"
      (characterLimitExceeded)="onCharacterLimitExceeded($event)"
    >
    </textarea>

    <label
      *ngIf="label && variant !== textboxVariants.CLASSIC"
      class="sch-input-label {{ variant }}"
      for="{{ id }}"
      [attr.readonly]="readonly"
      [attr.disabled]="disabled"
    >
      {{ label }}
    </label>
  </div>
</div>
