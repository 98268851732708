import { Injectable } from '@angular/core';
import {
  parsePhoneNumber,
  isValidPhoneNumber,
  CountryCode,
  AsYouType,
  getCountryCallingCode,
  NumberFormat,
  PhoneNumber,
} from 'libphonenumber-js';
import { PhoneValidatorInterface } from './phone-number.interface';

@Injectable()
export class PhoneValidator implements PhoneValidatorInterface {
  isValidPhone(phoneNumber: string, countryCode: string): boolean {
    try {
      return isValidPhoneNumber(
        phoneNumber,
        countryCode.toUpperCase() as CountryCode
      );
    } catch (error) {
      console.error(
        `${PhoneValidator.name} -> ${this.isValidPhone.name} -> ${error}`
      );
      return false;
    }   
  }

  formatToE164(phoneNumber: string, countryCode: string): string {
    const e164Format: NumberFormat = 'E.164';
    const number = parsePhoneNumber(
      phoneNumber,
      countryCode.toUpperCase() as CountryCode
    );
    const formatE164 = number.format(e164Format);
    return formatE164;
  }

  formatFromE164(E164PhoneNumber: string): PhoneNumber {
    const number = parsePhoneNumber(E164PhoneNumber);
    return number;
  }

  getFormattedPhoneNumber(countryCode: string, unformattedPhoneNumber: string) {
    const number = parsePhoneNumber(
      unformattedPhoneNumber,
      countryCode.toUpperCase() as CountryCode
    );
    const nationalNumber = number.format("NATIONAL");
    const formatted = new AsYouType(
      countryCode.toUpperCase() as CountryCode
    ).input(nationalNumber);
    return formatted;
  }

  getPhoneCode(countryCode: string) {
    return getCountryCallingCode(
      countryCode.toUpperCase() as CountryCode
    ).toString();
  }
}
