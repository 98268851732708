import { Injectable } from '@angular/core';
import { AlertComponent } from '../components/alert/alert.component';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from './notification.service';

interface config {
    position: any;
    width: any;
    delay: number;
    autohide: boolean;
    stacking: boolean;
    offset: number;
    animation: boolean;
    data: {
      text: string;
    };
  }


  interface AlertParam {
      message: string,
      id: string | number,
      config?: config | any,
      bannerType?: string
  }

  interface bannerConfig extends config {
      position: 'top-center' | 'bottom-center';
  }


@Injectable({
  providedIn: 'root',
})
export class AlertService {

  constructor(private notificationService:NotificationService) { }

  info(data:AlertParam): void {
    this.notificationService.info(this.buildAlert(data));
  }

  success(data:AlertParam): void {
   this.notificationService.success(this.buildAlert(data));
  }

  error(data:AlertParam): void {
    this.notificationService.error(this.buildAlert(data));
  }

  warning(data:AlertParam): void {
    this.notificationService.warning(this.buildAlert(data));
  }

  banner(data: AlertParam): void {
    const alteredConfig = {
        ...data.config,
        position: data.config?.position,
    } as bannerConfig;

    const alteredData: AlertParam = {
        message: data.message,
        config:  alteredConfig ,
        bannerType: data.bannerType,
        id: data.id
    }
    this.notificationService.banner(this.buildAlert(alteredData));
  }

  getAlertRef(): BehaviorSubject<any> {
    return this.notificationService.getNotifiedComponentRef();
  }

  close(id:string){
    const stackedComponents = this.notificationService.getStackedComponents();
    const componentToBeClosed = stackedComponents.find((component:any) => {
        return component?.component?.refId === id;
    });
    componentToBeClosed?.close();
    // this.notificationService.getNotificationRef()?.close();
  }

  private buildAlert(
   data: AlertParam
  ) {

    return {
      componentToBeNotified: AlertComponent,
      message: data?.message,
      config: {
        ...data?.config,
        autohide: false,
        position: data?.config?.position || 'top-right',
      },
      bannerType: data?.bannerType
    }
  }


}




