import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { ThemeService } from './theme.service';
import { Navigation } from '@seech/layout-ng';
import { ModalService } from '@seech/ux-ng';
import { SettingsComponent } from './modules/settings/settings.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {
  year = new Date().getFullYear();
  title = 'Demo';
  logoSrc = 'assets/images/logo_desktop_white.png';
  sidenavLogoSrc = 'assets/images/logo_desktop.png';
  private breakPointSubscription = new Subscription();
  themes = this.themeService.themes
  storybookDropdown = [
    { route: 'host-ng', label: 'All (Host)', id: 'storybook-host-ng' },
    { route: 'layout-ng', label: 'Layout', id: 'storybook-layout-ng'},
    { route: 'controls-ng', label: 'Controls', id: 'storybook-controls-ng' },
    { route: 'ux-ng', label: 'UX',  id: 'storybook-ux-ng'  },
    { route: 'media-ng', label: 'Media',  id: 'storybook-media-ng'  },
    { route: 'visualizations-ng', label: 'Visualization', id: 'storybook-visualizations-ng'  },
  ];

  storyOptions = [
    {
      label: 'Controls',
      value: 'controls',
    },
    {
      label: 'UX',
      value: 'ux',
    },
    {
      label: 'Visualization',
      value: 'visualization',
    },
    {
      label: 'Layout',
      value: 'layout',
    },
    {
      label: 'Media',
      value: 'media',
    },
  ];

  selectedStory = '';
  controlsNgRoute = 'controls';
  uxNgRoute = 'ux';
  visualizationRoute = 'visualizations';
  mediaRoute = 'media';
  layoutRoute = 'layout';

  navigationItems: Navigation[] = [
    {
      icon: 'layout',
      label: 'Layout',
      route: 'layout',
      id: 'side-nav-layout'
    },
    {
      icon: 'toggle-off',
      label: 'Controls',
      route: 'controls',
      id: 'side-nav-controls'
    },
    {
      icon: 'account-circle',
      label: 'UX',
      route: 'ux',
      id: 'side-nav-ux'
    },
    {
      icon: 'play',
      label: 'Media',
      route: 'media',
      id: 'side-nav-media'
    },
    {
      icon: 'info-outline',
      label: 'Icons',
      route: 'icons',
      id: 'side-nav-icons'
    },
    {
      icon: 'trending',
      label: 'Visualizations',
      route: 'visualization',
      id: 'side-nav-visualization'
    },
  ];

  bottomNavigationItems: Navigation[] = [
    {
      icon: 'settings',
      label: 'Settings',
      id: 'side-nav-setting'
    },
  ];

  SIDE_NAV_COLLAPSE_SATE = 'SIDENAV';

  constructor(
    private breakpointObserver: BreakpointObserver,
    public themeService: ThemeService,
    private modalService: ModalService
  ) {}

  displayIsMobile = this.deviceDisplayIsSmall();
  deviceDisplayIsSmall() {
    return this.breakpointObserver.isMatched([
      Breakpoints.Small,
      Breakpoints.XSmall,
    ]);
  }

  navigationSelectionChange(drawer: any) {
    if (this.deviceDisplayIsSmall()) {
      drawer.close();
    }
  }

  openSettingsDialog() {
    this.modalService.open(SettingsComponent,
      {
        ignoreBackdropClick: false,
        modalClass: 'modal-dialog-centered modal-md',
      }
    );
  }

  ngOnInit(): void {
    this.breakPointSubscription = this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.XSmall])
      .subscribe((state) => {
        this.displayIsMobile = state.matches;
      });
  }

  onThemeChange(theme: string) {
    this.themeService.setTheme(theme);
  }

  getStorybookUrlByName(name: string) {
    return environment.storybook[name || this.storybookDropdown[0].route];
  }

  ngOnDestroy(): void {
    this.breakPointSubscription.unsubscribe();
  }
}
