<div class="sch-input">
    <label *ngIf="label" 
      class="sch-input-label" 
      for="{{ id }}" 
      [attr.readonly]="readonly" 
      [attr.disabled]="disabled">
      {{ label }}
    </label>
  
    <textarea 
      [id]="id"
      (isValid)="handleValidation($event)"
      [(ngModel)]="value"
      (change)="onInput()"
      [autocomplete]="autocomplete"
      class="sch-text-area"
      [placeholder]="placeholder"
      [readonly]="readonly"
      [disabled]="disabled">
    </textarea>
  </div>
  