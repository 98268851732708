import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnChanges } from '@angular/core';
import { ToastService } from '../../services';
import { IconDirective } from '@seech/shared-ng';


@Component({
  selector: 'seech-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    IconDirective
  ],
})
export class ToastComponent implements OnChanges {
  @Input() color = '';
  @Input({ required: true }) title = '';
  @Input() message = '';
  @Input({ required: true }) type = '';
  @Input() config: any = {};
  @Input() delay = 0;
  @Input() uId = '';
  @Input() width="";
  @Input() height; //pass in the unit alongside height being specified eg '4rem/4px'
  @Input({ required: true }) id: string | number = '';
  @Input() titleIcon: any = '';

  classes = this.getToastBackground();
  progress = 100;
  notificationRef: any;
  toastService: ToastService | null | any = this.getToastService();

  // function to reduce the progress bar according to the time passed in the config

  reduceProgress() {
    const delayTime = this.notificationRef?.config?.delay || 0;
    if (delayTime) {
      const interval = setInterval(() => {
        if (this.progress > 0) {
          this.progress -= 1;
          this.cdr.detectChanges();
        } else {
          clearInterval(interval);
        }
      }, delayTime / 100);
    }
  }

  constructor(
    private injector: Injector,
    private cdr: ChangeDetectorRef
  ) {
    if (this.toastService) {
      this.toastService.getToastRef().subscribe({
        next: (data: any) => {
          if (data) {
            this.notificationRef = data.component;
            this.reduceProgress();
          }
        },
      })
    }
  }

  ngOnChanges(): void {
    this.classes = this.getToastBackground();
  }
  getToastBackground() {
    return `bg-${this.color}`;
  }

  private getToastService(): ToastService | null {
    try {
      return this.injector.get<ToastService>(ToastService);
    } catch (e) {
      console.error('ToastService not found:', e);
      return null;
    }
  }

  close() {
    this.toastService.close(this.uId)
  }

}
