export type TooltipPosition = 'top' | 'right' | 'bottom' | 'left';

export type TextboxVariant = 'classic' | 'modern' | 'hybrid';
export type SwitchVariant = 'classic' | 'modern';
export type NormalRounded = 'normal' | 'rounded';
export type NormalCircle = 'normal' | 'circle';
export type Fill = 'solid' | 'outline' | 'shaded';
export type Shape = 'rounded' | 'circle' | 'normal';
export type Size = 'md' | 'sm' | 'lg';
export type Direction = 'up' | 'start' | 'end' | 'down';
export type TextTransform = 'uppercase' | 'lowercase' | 'capitalize';
export type ProgressBarStyle = 'horizontal' | 'circular';
export type Color =
  | 'secondary'
  | 'dark'
  | 'tertiary'
  | 'danger'
  | 'info'
  | 'warning'
  | 'success'
  | 'primary'
  | 'light';

export const IconArray = [
  'geotag',
  'home',
  'secured',
  'tune',
  'search',
  'reply',
  'happy',
  'send',
  'share',
  'love',
  'person-add',
  'settings',
  'map',
  'boundary',
  'notification',
  'block',
  'comment',
  'collapse',
  'cancel',
  'event-check',
  'copy',
  'edit',
  'contacts',
  'event',
  'expand',
  'logout',
  'group',
  'groups',
  'bookmark',
  'chat',
  'auto-renew',
  'alt-email',
  'add-photo',
  'add-mail',
  'add-location',
  'add-event',
  'add-circle',
  'person',
  'person-off',
  'calendar',
  'pin',
  'person-pin',
  'account-circle',
  'layout',
  'play',
  'toggle-off',
  'trending',
  'colors',
  'fullscreen-exit',
  'fullscreen',
  'zoom-out',
  'zoom-in',
  'close',
  'next-arrow',
  'prev-arrow',
  'arrow-forward',
  'clock',
  'priority',
  'arrow-back',
  'menu',
  'dashboard',
  'help',
  'login',
  'facebook',
  'twitter',
  'instagram',
  'mail',
  'bid-fill',
  'bookmark-fill',
  'energy',
  'location-fill',
  'more-horizontal-fill',
  'more-vertical-fill',
  'star-badge-fill',
  'arrow-drop-up',
  'note-approve',
  'note-delete',
  'note-stack',
  'stacks',
  'briefcase',
  'folder',
  'headset',
  'info-outline',
  'visibility',
  'visibility-off',
  'video-camera',
  'phone',
  'dotted-clock',
  'checkmark',
  'vert-swap-arrow',
  'location-outline',
  'filter-outline',
  'android-arrow-back',
  'delete',
  'double-checkmark',
  'edit-box',
  'plus',
  'badge',
  'verified',
  'arrow-diagonal',
  'bid-outline',
  'star',
  'volume-off',
  'volume-on',
  'pause-circle',
  'download',
  'invitation',
  'mic',
  'playlist-add-fill',
  'dashboard-rounded',
  'workspace',
  'product',
  'professional',
  'wallet',
  'work',
  'card',
  'layers',
  'portfolio',
  'messaging',
  'inbox',
  'handshake',
  'clipboard',
  'circled-bookmark',
  'upload-file',
  'upload-image',
  'notifications-unread',
  'photo-library',
  'photo-prints',
  'check-circle',
  'warning',
  'arrow-drop-down',
  'chevron-right',
  'folder-open',

  'account-circle-fill',
  'add-circle-fill',
  'add-event-fill',
  'add-location-fill',
  'add-photo-fill',
  'alt-email-fill',
  'arrow-back-fill',
  'arrow-diagonal-fill',
  'arrow-drop-down-fill',
  'arrow-drop-up-fill',
  'arrow-forward-fill',
  'autorenew-fill',
  'bid-fill',
  'block-fill',
  'briefcase-fill',
  'calendar-fill',
  'cancel-fill',
  'card-fill',
  'chat-fill',
  'check-circle-fill',
  'checkmark-fill',
  'clock-fill',
  'close-fill',
  'collapse-fill',
  'colors-fill',
  'comment-fill',
  'contacts-fill',
  'copy-fill',
  'dashboard-fill',
  'delete-fill',
  'dotted-clock-fill',
  'double-checkmark-fill',
  'download-fill',
  'edit-box-fill',
  'edit-fill',
  'energy-fill',
  'event-check-fill',
  'event-fill',
  'expand-fill',
  'filter-fill',
  'folder-fill',
  'fullscreen-exit-fill',
  'fullscreen-fill',
  'geotag-fill',
  'group-fill',
  'groups-fill',
  'happy-fill',
  'headset-fill',
  'help-fill',
  'home-fill',
  'info-fill',
  'layers-fill',
  'layout-fill',
  'location-on-fill',
  'login-fill',
  'logout-fill',
  'love-fill',
  'mail-fill',
  'map-fill',
  'menu-fill',
  'next-arrow-fill',
  'note-approve-fill',
  'note-delete-fill',
  'note-stack-fill',
  'notification-fill',
  'notifications-unread-fill',
  'pause-circle-fill',
  'person-add-fill',
  'person-fill',
  'person-off-fill',
  'person-pin-fill',
  'phone-fill',
  'photo-library-fill',
  'photo-prints-fill',
  'pin-fill',
  'play-fill',
  'plus-fill',
  'prev-arrow-fill',
  'priority-fill',
  'reply-fill',
  'search-fill',
  'secure-fill',
  'send-fill',
  'settings-fill',
  'share-fill',
  'stacks-fill',
  'star-fill',
  'toggle-off-fill',
  'trending-fill',
  'tune-fill',
  'upload-file-fill',
  'upload-image-fill',
  'verified-fill',
  'vert-swap-arrow-fill',
  'video-camera-fill',
  'visibility-fill',
  'visibility-off-fill',
  'volume-off-fill',
  'volume-on-fill',
  'wallet-fill',
  'warning-fill',
  'work-fill',
  'workspace-fill',
  'zoom-in-fill',
  'zoom-out-fill',
  'folder-open-fill',
  'chevron-right-fill'
] as const;

export type Icon = (typeof IconArray)[number];

export type FileType =
  | 'text/plain' // Plain text documents
  | 'application/pdf' // PDF documents
  | 'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document' // MS Word documents
  | 'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // MS Excel documents
  | 'application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation' // MS PowerPoint documents
  | 'image/jpeg' // JPEG images
  | 'image/png' // PNG images
  | 'image/gif' // GIF images
  | 'audio/mpeg' // MP3 audio
  | 'audio/wav' // WAV audio
  | 'video/mp4' // MP4 video
  | 'audio/*' // All audio formats
  | 'video/*' // All video formats
  | 'image/*' // All image formats
  | 'application/json'; // JSON file

export const DIRECTION = {
  END: 'end' as Direction,
  UP: 'up' as Direction,
  DOWN: 'down' as Direction,
  START: 'start' as Direction,
};

export const TEXT_TRANSFORM = {
  UPPERCASE: 'uppercase' as TextTransform,
  LOWERCASE: 'lowercase' as TextTransform,
  CAPITALIZE: 'capitalize' as TextTransform,
};

export const FILE_TYPES = {
  TEXT: 'text/plain' as FileType,
  ALL_IMAGES: 'image/*' as FileType,
  ALL_VIDEOS: 'video/*' as FileType,
  ALL_AUDIO: 'audio/*' as FileType,
  MPEG: 'audio/mpeg' as FileType,
  WORD_DOC:
    'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document' as FileType,
  MS_EXCEL:
    'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' as FileType,
  JPEG: 'image/jpeg' as FileType,
  PNG: 'image/png' as FileType,
  PDF: 'application/pdf' as FileType,
  GIF: 'image/gif' as FileType,
  POWER_POINT:
    'application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation' as FileType,
  MP4: 'video/mp4' as FileType,
  WAV: 'audio/wav' as FileType,
  JSON: 'application/json' as FileType,
};

export const FILL = {
  SOLID: 'solid' as Fill,
  OUTLINE: 'outline' as Fill,
  SHADED: 'shaded' as Fill,
};

export const BTN = {
  SHAPE: {
    ROUNDED: 'rounded' as Shape,
    CIRCLE: 'circle' as Shape,
    NORMAL: 'normal' as Shape,
  },
};

export const SIZE = {
  SMALL: 'sm' as Size,
  LARGE: 'lg' as Size,
  MEDIUM: 'md' as Size,
};

export const TEXTBOXVARIANT = {
  CLASSIC: 'classic' as TextboxVariant,
  MODERN: 'modern' as TextboxVariant,
  HYBRID: 'hybrid' as TextboxVariant,
};

export const SWITCHVARIANT = {
  CLASSIC: 'classic' as SwitchVariant,
  MODERN: 'Modern' as SwitchVariant,
};

export const NORMALROUNDED = {
  NORMAL: 'normal' as NormalRounded,
  ROUNDED: 'rounded' as NormalRounded,
};

export const NORMALCIRCLE = {
  NORMAL: 'normal' as NormalCircle,
  CIRCLE: 'circle' as NormalCircle,
};

export const COLOR = {
  PRIMARY: 'primary' as Color,
  TERTIARY: 'tertiary' as Color,
  SECONDARY: 'secondary' as Color,
  SUCCESS: 'success' as Color,
  DARK: 'dark' as Color,
  WARNING: 'warning' as Color,
  DANGER: 'danger' as Color,
  LIGHT: 'light' as Color,
  INFO: 'info' as Color,
};

export const ICON = {
  NAME: {
    LINK: 'link' as Icon,
    SETTINGS: 'settings' as Icon,
    MAP: 'map' as Icon,
    REPLY: 'reply' as Icon,
    PERSON_PIN: 'person-pin' as Icon,
    LOVE: 'love' as Icon,
    SEND: 'send' as Icon,
    HAPPY: 'happy' as Icon,
    SHARE: 'share' as Icon,
    PERSON_ADD: 'person-add' as Icon,
    TUNE: 'tune' as Icon,
    SECURED: 'secured' as Icon,
    SEARCH: 'search' as Icon,
    PIN: 'pin' as Icon,
    PERSON: 'person' as Icon,
    PERSON_OFF: 'person-off' as Icon,
    NOTIFICATION: 'notification' as Icon,
    LOGOUT: 'logout' as Icon,
    HOME: 'home' as Icon,
    GROUPS: 'groups' as Icon,
    GROUP: 'group' as Icon,
    GEOTAG: 'geotag' as Icon,
    EXPAND: 'expand' as Icon,
    EVENT: 'event' as Icon,
    EVENT_CHECK: 'event-check' as Icon,
    EDIT: 'edit' as Icon,
    COPY: 'copy' as Icon,
    CONTACTS: 'contacts' as Icon,
    COMMENT: 'comment' as Icon,
    COLLAPSE: 'collapse' as Icon,
    CANCEL: 'cancel' as Icon,
    CALENDER: 'calender' as Icon,
    CHAT: 'chat' as Icon,
    BOUNDARY: 'boundary' as Icon,
    BOOKMARK: 'bookmark' as Icon,
    BLOCK: 'block' as Icon,
    AUTO_RENEW: 'auto-renew' as Icon,
    ALT_EMAIL: 'alt-email' as Icon,
    ADD_PHOTO: 'add-photo' as Icon,
    ADD_EMAIL: 'add-mail' as Icon,
    ADD_LOCATION: 'add-location' as Icon,
    ADD_EVENT: 'add-event' as Icon,
    ADD_CIRCLE: 'add-circle' as Icon,
  },
};
