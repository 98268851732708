import { Directive, ElementRef, EventEmitter, Input, Output, OnDestroy, AfterViewInit } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Directive({
  selector: '[sch-scroll-end-notifier]',
  standalone: true,
})
export class SharedScrollEndNotifierDirective implements AfterViewInit, OnDestroy {
  @Input() offset = 100; // Default offset value in pixels
  @Output() scrollEnd = new EventEmitter<void>();

  private scrollSubscription!: Subscription;
  private isNearEnd = false;

  constructor(private el: ElementRef) { }

  ngAfterViewInit(): void {
    // Listen to the scroll event on the element
    this.scrollSubscription = fromEvent(this.el.nativeElement, 'scroll')
      .pipe(throttleTime(200)) // Throttle for performance
      .subscribe(() => this.onScroll());
  }

  private onScroll(): void {
    const element = this.el.nativeElement;
    const scrollPosition = element.scrollTop + element.clientHeight;
    const offsetThreshold = element.scrollHeight - this.offset;

    if (scrollPosition >= offsetThreshold && !this.isNearEnd) {
      this.isNearEnd = true;
      this.scrollEnd.emit();
    } else if (scrollPosition < offsetThreshold) {
      // Reset the flag if the user scrolls up
      this.isNearEnd = false;
    }
  }

  ngOnDestroy(): void {
    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }
  }
}
