import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ContentChildren,
  HostBinding,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { SEECH_LAYOUT_CONTAINER } from '../../layout.token';
import { LayoutContainerComponent } from '../layout/layout-container.component';
import { Breakpoints } from '@angular/cdk/layout';
import { SideNavComponent } from '../../components/side-nav/side-nav.component';
import { LayoutContentComponent } from '../../components/layout-content/layout-content.component';
import { ContentMargin } from '../../content-margin.interface';

@Component({
  selector: 'seech-gold-layout',
  exportAs: 'seechGoldLayout',
  templateUrl: './gold-layout.component.html',
  styleUrls: ['../layout/layout.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: SEECH_LAYOUT_CONTAINER,
      useExisting: GoldLayoutComponent,
    },
  ],
})
export class GoldLayoutComponent
  extends LayoutContainerComponent
  implements OnChanges, OnInit, OnDestroy, ContentMargin
{
  @HostBinding('attr.ngSkipHydration') _ngSkipHydrationAttribute = '';
  deviceDisplayIsMobile = false;

  private breakPointSubscription = new Subscription();
  override ngOnDestroy(): void {
    this.breakPointSubscription.unsubscribe();
  }
  ngOnInit(): void {
    this.deviceDisplayIsMobile = this.deviceDisplayIsSmall();
    this.breakPointSubscription = this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.XSmall])
      .subscribe((state) => {
        this.deviceDisplayIsMobile = state.matches;
      });
  }

  deviceDisplayIsSmall() {
    return this.breakpointObserver.isMatched([
      Breakpoints.Small,
      Breakpoints.XSmall,
    ]);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    this._containerClass = this.getContainerClass();
  }

  @ContentChildren(SideNavComponent, {
    descendants: true,
  })
  override sideNavComponents: QueryList<SideNavComponent> | any = undefined;

  @ContentChild(LayoutContentComponent)
  override content: LayoutContentComponent | any = undefined;

  @HostBinding('class') _containerClass = this.getContainerClass();

  getContainerClass() {
    return this.disableFullHeight
      ? 'seech-layout-container'
      : 'seech-layout-container seech-sidenav-container';
  }

  @HostBinding('class.seech-layout-container-explicit-backdrop')
  get _backdrop() {
    return this.backdropOverride;
  }
}
