<div class="sch-input">
  <label
    *ngIf="label"
    class="sch-input-label"
    for="{{ id }}"
    [attr.readonly]="readonly"
    [attr.disabled]="disabled"
  >
    {{ label }}
  </label>

  <div
    (click)="readonly || disabled ? null : toggleDropdown()"
    class="position-relative"
  >
    <input
      type="text"
      class="sch-input-field cursor-pointer"
      id="{{ id }}"
      value="{{ selectedValue?.value || '' }}"
      [placeholder]="placeholder"
      (input)="onInput($event)"
      [readonly]="readonly"
      [disabled]="disabled"
      autocomplete="{{ autocomplete }}"
    />

    <i
      *ngIf="!readonly"
      [id]="id"
      [sch-icon]="showDropdown ? 'expand' : 'collapse'"
      class="sch-input-icon sch-select-icn"
    ></i>
  </div>

  <div
    *ngIf="showDropdown && options.length > 0"
    class="sch-select-dropdown"
    #dropdownMenu
    sch-scroll-end-notifier
    [offset]="threshold"
    (scrollEnd)="onScrollEnd()"
  >
    <ng-container>
      <div
        *ngFor="let option of options; index as i"
        class="sch-select-option"
        [ngClass]="{ active: selected === option.value }"
        (click)="selectOption(option)"
      >
        <div class="d-flex my-1">
          <div class="flex-grow-1 sch-option-key">{{ option.label }}</div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<!--  -->
