<div id="{{id}}" class="sch-dialog">
    <div class="dialog-content">
      <div class="dialog-header">
        <h5 class="dialog-title" [innerHTML]="title"></h5>
      </div>
      <div class="dialog-body" [innerHTML]="content"></div>
      <div class="dialog-footer">
        <button *ngIf="actionBtnText"
        [class]="actionBtnClasses + ' action-btn'" 
        (click)="onAction()">{{actionBtnText}}</button>
        <button 
        [class]="cancelBtnClasses + ' cancel-button'"
         (click)="onCancel()">{{cancelBtnText}}</button>
      </div>
    </div>
 </div>
  