<div class="popconfirm-popover shadow-4">
    <div class="popconfirm">
        <ng-container *ngIf="title; else bodyTemplate">
            <p class="popconfirm-message">
                <span class="popconfirm-message-text"> {{ title }} </span>
            </p>
        </ng-container>

        <ng-template #bodyTemplate>
            <ng-content select="[body]"></ng-content>
        </ng-template>

        <div class="d-flex justify-content-center gap-4">
            <button sch-button [color]="cancelBtnColor" fill="outline" size="sm" class="cancel-btn shadow-none !capitalize" 
            (click)="onConfirm(false)">
                {{ cancelLabel }}
            </button>
            <button sch-button [color]="confirmBtnColor" size="sm" class="confirm-btn shadow-none !capitalize" 
            (click)="onConfirm(true)">
                {{ confirmLabel }}
            </button>
        </div>
    </div>
</div>
