<div class="seech-layout-backdrop" (click)="onBackdropClicked()" *ngIf="hasBackdrop"
  [class.seech-layout-shown]="isShowingBackdrop()"></div>

<header class="layout-header bg-body">
  <ng-content select="[header]">
  </ng-content>
</header>

<ng-content select="seech-side-nav"></ng-content>
<ng-content select="seech-layout-content">
</ng-content>
