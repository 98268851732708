import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { IconsNgModule } from '@seech/icons-ng';
import { ModalService, UxNgModule } from '@seech/ux-ng';
import { ControlsNgModule } from '@seech/controls-ng';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { SettingsService } from './settings.service';

@Component({
  standalone: true,
  selector: 'app-settings',
  imports: [CommonModule, UxNgModule, IconsNgModule, ControlsNgModule],
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, OnDestroy {
  controlsCollapsed = true;
  generalCollapsed = true;
  mediaCollapsed = true;
  iconsCollapsed = true;

  allSettings: any;

  private subscription = new Subscription();

  constructor(
    private settingsService: SettingsService,
    private router: Router,
    private modalService: ModalService
  ) {
    const currentRoute = this.router.url;

    if (currentRoute) {
      switch (true) {
        case currentRoute.includes('controls'):
          this.controlsCollapsed = false;
          break;
        case currentRoute.includes('media'):
          this.mediaCollapsed = false;
          break;
        case currentRoute.includes('icons'):
          this.iconsCollapsed = false;
          break;
        default:
          break;
      }
    }
  }

  ngOnInit(): void {
    this.subscription.add(
      this.settingsService.getSettings().subscribe((settings) => {
        this.allSettings = settings;
      })
    );
  }

  saveSettings(selectedObj: any) {
    this.settingsService.updateSettings(this.allSettings);
  }

  closeModal() {
    this.modalService.close();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
