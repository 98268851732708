export class Utilities {
  static deepClone(obj: any, hash = new WeakMap()): any {
    // Do not try to clone non-object values
    if (Object(obj) !== obj) return obj;

    // Handle Date
    if (obj instanceof Date) return new Date(obj);

    // Handle RegExp
    if (obj instanceof RegExp) return new RegExp(obj.source, obj.flags);

    // Handle circular references
    if (hash.has(obj)) return hash.get(obj);

    const result: any =
      obj instanceof Set
        ? new Set(obj) // Handle Set
        : obj instanceof Map
        ? new Map(
            Array.from(obj, ([key, val]) => [key, Utilities.deepClone(val, hash)])
          ) // Handle Map
        : obj.constructor
        ? new obj.constructor() // Handle objects with a constructor
        : Object.create(null); // Handle objects without a constructor

    hash.set(obj, result);

    if (obj instanceof Set) {
      obj.forEach((val) => result.add(Utilities.deepClone(val, hash)));
    } else if (obj instanceof Map) {
      obj.forEach((val, key) => result.set(key, Utilities.deepClone(val, hash)));
    }

    return Object.assign(
      result,
      ...Object.keys(obj).map((key) => ({
        [key]: Utilities.deepClone(obj[key], hash),
      }))
    );
  }

  static generateUUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
}
