import {FILL as SHARED_FILL} from '@seech/shared-ng';

export const FILL = SHARED_FILL;


export const CONTROL_TYPE = {
    TEXT: 'text',
    NUMBER: 'number',
    FILE: 'file',
    SELECT: 'select',
    SWITCH: 'switch',
    PASSWORD: 'password',
    BUTTON: 'button',
    DATE: 'date',
    TIME: 'time',
    DATETIME: 'date-time',
    URL: 'url',
    EMAIL: 'email',
    PHONE: 'phone',
    AUTOCOMPLETE: 'auto-complete',
    COMBO_BUTTON: 'combo-button',
    COMBO_SELECT: 'combo-select',
    ZIPCODE: 'zip-code',
    SSN: 'us-ssn'
}

export const TEXTBOX_VARIANT = {
    CLASSIC: 'classic',
    MODERN: 'modern',
    HYBRID: 'hybrid',
}
