import { InjectionToken } from "@angular/core";
import { ContentMargin } from "./content-margin.interface";

export function throwMatDuplicatedLayoutError(position: string) {
  throw Error(`A layout was already declared for 'position="${position}"'`);
}

export type AutoFocusTarget = 'dialog' | 'first-tabbable' | 'first-heading';

export type LayoutToggleResult = 'open' | 'close';

/** Layout and SideNav display modes. */
export type LayoutMode = 'over' | 'push' | 'side';

export const SEECH_LAYOUT_DEFAULT_AUTOSIZE = new InjectionToken<boolean>(
  'SEECH_LAYOUT_DEFAULT_AUTOSIZE',
  {
    providedIn: 'root',
    factory: SEECH_LAYOUT_DEFAULT_AUTOSIZE_FACTORY,
  }
);

export const SEECH_LAYOUT_CONTAINER = new InjectionToken<ContentMargin>(
  'SEECH_LAYOUT_CONTAINER'
);

export function SEECH_LAYOUT_DEFAULT_AUTOSIZE_FACTORY(): boolean {
  return false;
}
