import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormsModule, NG_VALIDATORS, ReactiveFormsModule, ValidationErrors, Validator, Validators } from '@angular/forms';

import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';
import { ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
@Component({
  selector: 'seech-textarea',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MdbFormsModule,
  ],
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true,
    },
  ],
})
export class TextareaComponent implements ControlValueAccessor, Validator {
  @Input() label?: string;
  @Input() id = '';
  @Input() value: any = '';
  @Input() autocomplete?: 'on' | 'off' | null;
  @Input() placeholder = '';
  @Input() readonly = false;
  @Input() disabled = false;
  @Output() valueChange: any = new EventEmitter<any>();
  @Output() valid: any = new EventEmitter<boolean>();
  @Output() actionIconClick: any = new EventEmitter<void>();

  validInput = true;

  handleValidation(event: any): void {
    this.validInput = event;
    this.valid.emit(this.validInput);
  }

  onInput(): void {
    this.valueChange.emit(this.value);
    this.onChange(this.value); // this will notify the parent form of the value change
    this.onTouched(); // this will notify the parent form that the input field has been touched
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private onChange = (value: any) => {
    // console.log("onChange", value);
  };
  private onTouched = () => {
    // console.log("onTouched");
  };

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }


  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    const valueIsRequired = control.hasValidator(Validators.required);
    const requiredError = { required: true };

    if (!value && valueIsRequired) {
      return requiredError;
    }

    return null;
  }

}

