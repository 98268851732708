import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { CONTROL_TYPE } from '../constants';
import { DateFormat } from '../components';
@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  getValidator(type: string): ValidatorFn | null {
    switch (type) {
      case CONTROL_TYPE.EMAIL:
        return this.emailValidator();
      case CONTROL_TYPE.NUMBER:
        return Validators.pattern('^[0-9]+$');
      case CONTROL_TYPE.PASSWORD:
        return this.passwordValidator();
      default:
        return null
    }
  }

  private emailValidator(): ValidatorFn {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return (control: AbstractControl): { [key: string]: any } | null => {
      const valid = emailRegex.test(control.value);
      return valid ? null : { invalidEmail: true };
    };
  }

  private passwordValidator(): ValidatorFn {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-_+=#$@$!%*?&":;<.,/|'>]).{8,}$/;
    return (control: AbstractControl): { [key: string]: any } | null => {
      const valid = passwordRegex.test(control.value);
      return valid ? null : { invalidPassword: true };
    };
  }

  //  return true type number has no letters and no special characters nd false if it has

  isNumber(value: string): boolean {
    const regExp = new RegExp(/\D+/g, '');
    return !isNaN(Number(value)) || !regExp.test(value);
  }

  validateDate(dateString: string, format: DateFormat): boolean {
    const regexMap: { [key in DateFormat]: RegExp } = {
      // These patterns now accept years from 1001 to 9999
      'dd/mm/yyyy': /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(1\d{3}|[2-9]\d{3})$/,
      'mm/dd/yyyy': /^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/(1\d{3}|[2-9]\d{3})$/,
      'yyyy/mm/dd': /^(1\d{3}|[2-9]\d{3})\/(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])$/,
      // Two-digit year formats inherently can't specify a century without additional context
      'dd/mm/yy': /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/\d\d$/,
      'mm/dd/yy': /^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/\d\d$/,
      'yy/mm/dd': /^\d\d\/(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])$/
    };

    const regex = regexMap[format];
    return regex.test(dateString);
}


  validateTime(timeString: string, format: '24' | '12'): boolean {
    if (format === '24') {
      return /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(timeString);
    } else {
      return /^(1[0-2]|0?[1-9]):[0-5][0-9] ?(am|pm|AM|PM)$/.test(timeString);
    }
  }

  validateDateTime(dateTimeString: { date: string, time: string }, format: { date: DateFormat; time: '24' | '12' }): boolean {
    return this.validateDate(dateTimeString?.date, format.date) && this.validateTime(dateTimeString?.time, format.time);
  }

  reformatDate(inputDate: string, targetFormat: DateFormat): string {
    //  convertr Fri Dec 15 2023 00:00:00 GMT+0100 (West Africa Standard Time) to 15/12/2023 then handle the reformating

    const date = new Date(inputDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    inputDate = `${day}/${month}/${year}`;

    // Split the input date into parts  
    
    const dateParts = inputDate.split(/[/-]/).map(part => part.padStart(2, '0')); // Ensure zero-padding

    // Determine the format of the input date
    const inputFormat = this.determineFormat(dateParts);

    if (!inputFormat) {
      return ''; // or throw an error if format cannot be determined
    }

    // Rearrange the date parts based on the target format
    return this.formatDateParts(dateParts, inputFormat, targetFormat);
  }

  private determineFormat(dateParts: string[]): DateFormat | null {
    // Check the length of the year part to infer the format
    const yearLength = dateParts.find(part => part.length === 4 || part.length === 2)?.length;

    if (yearLength === 4) {
      // Year is in yyyy format
      if (dateParts[0].length === 4) {
        return 'yyyy/mm/dd';
      } else {
        return 'mm/dd/yyyy';
      }
    } else if (yearLength === 2) {
      // Year is in yy format
      if (dateParts[0].length === 2) {
        return 'dd/mm/yy';
      } else {
        return 'mm/dd/yy';
      }
    }

    return null; // Return null if the format cannot be determined
  }


  private formatDateParts(dateParts: string[], inputFormat: DateFormat, targetFormat: DateFormat): string {
    let day, month, year;

    switch (inputFormat) {
      case 'dd/mm/yyyy':
      case 'dd/mm/yy':
        [day, month, year] = dateParts;
        break;
      case 'mm/dd/yyyy':
      case 'mm/dd/yy':
        [month, day, year] = dateParts;
        break;
      case 'yyyy/mm/dd':
      case 'yy/mm/dd':
        [year, month, day] = dateParts;
        break;
    }

    // Adjust year to match target format length
    year = targetFormat.includes('yyyy') ? year.padStart(4, '20') : year.slice(-2);

    switch (targetFormat) {
      case 'dd/mm/yyyy':
      case 'dd/mm/yy':
        return `${day}/${month}/${year}`;
      case 'mm/dd/yyyy':
      case 'mm/dd/yy':
        return `${month}/${day}/${year}`;
      case 'yyyy/mm/dd':
      case 'yy/mm/dd':
        return `${year}/${month}/${day}`;
      default:
        return ''; // Or return null, or handle the error as needed
    }
  }


}
