import { NgModule } from '@angular/core';
import { TooltipDirective } from '../directives';
import { TooltipComponent } from '../components';

const TOOLTIP = [TooltipDirective, TooltipComponent];
@NgModule({
  declarations: [],
  imports: TOOLTIP,
  exports: TOOLTIP,
})
export class TooltipModule {}
